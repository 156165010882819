/* tslint:disable */
/* eslint-disable */
/**
 * GHG Core API
 * # Description This is GHG Core API. 
 *
 * The version of the OpenAPI document: 0.0.142-SNAPSHOT
 * Contact: tomohiko.ozawa@sony.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Activities
 */
export interface Activities {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof Activities
     */
    'activities': Array<Activity>;
}
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * Activity ID
     * @type {number}
     * @memberof Activity
     */
    'id'?: number;
    /**
     * Category emission factor table ID
     * @type {number}
     * @memberof Activity
     */
    'categoryEmissionFactorTableId': number;
    /**
     * Emission factor ID
     * @type {number}
     * @memberof Activity
     */
    'emissionFactorId': number;
    /**
     * Activity amount
     * @type {number}
     * @memberof Activity
     */
    'quantity': number;
    /**
     * 月
     * @type {string}
     * @memberof Activity
     */
    'month'?: string;
    /**
     * Memo for each activity
     * @type {string}
     * @memberof Activity
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface ActivityAttr
 */
export interface ActivityAttr {
    /**
     * Category emission factor table ID
     * @type {number}
     * @memberof ActivityAttr
     */
    'categoryEmissionFactorTableId': number;
    /**
     * Emission factor ID
     * @type {number}
     * @memberof ActivityAttr
     */
    'emissionFactorId': number;
    /**
     * Activity amount
     * @type {number}
     * @memberof ActivityAttr
     */
    'quantity': number;
    /**
     * 月
     * @type {string}
     * @memberof ActivityAttr
     */
    'month'?: string;
    /**
     * Memo for each activity
     * @type {string}
     * @memberof ActivityAttr
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface ActivityId
 */
export interface ActivityId {
    /**
     * Activity ID
     * @type {number}
     * @memberof ActivityId
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ActivityWithEmissionFactor
 */
export interface ActivityWithEmissionFactor {
    /**
     * 排出係数テーブル ID
     * @type {number}
     * @memberof ActivityWithEmissionFactor
     */
    'emission_factor_table_id': number;
    /**
     * 排出源単位名
     * @type {string}
     * @memberof ActivityWithEmissionFactor
     */
    'name'?: string;
    /**
     * 温対法
     * @type {number}
     * @memberof ActivityWithEmissionFactor
     */
    'anti_warming_id'?: number;
    /**
     * 省エネ法
     * @type {number}
     * @memberof ActivityWithEmissionFactor
     */
    'energy_saving_id'?: number;
    /**
     * 排出係数
     * @type {number}
     * @memberof ActivityWithEmissionFactor
     */
    'value'?: number;
    /**
     * 排出係数の単位
     * @type {string}
     * @memberof ActivityWithEmissionFactor
     */
    'unit'?: string;
    /**
     * 年度
     * @type {number}
     * @memberof ActivityWithEmissionFactor
     */
    'year'?: number;
    /**
     * Activity ID
     * @type {number}
     * @memberof ActivityWithEmissionFactor
     */
    'id'?: number;
    /**
     * Category emission factor table ID
     * @type {number}
     * @memberof ActivityWithEmissionFactor
     */
    'categoryEmissionFactorTableId': number;
    /**
     * Emission factor ID
     * @type {number}
     * @memberof ActivityWithEmissionFactor
     */
    'emissionFactorId': number;
    /**
     * Activity amount
     * @type {number}
     * @memberof ActivityWithEmissionFactor
     */
    'quantity': number;
    /**
     * 月
     * @type {string}
     * @memberof ActivityWithEmissionFactor
     */
    'month'?: string;
    /**
     * Memo for each activity
     * @type {string}
     * @memberof ActivityWithEmissionFactor
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface ActivityWithEmissionFactorList
 */
export interface ActivityWithEmissionFactorList {
    /**
     * 
     * @type {Array<ActivityWithEmissionFactor>}
     * @memberof ActivityWithEmissionFactorList
     */
    'data': Array<ActivityWithEmissionFactor>;
}
/**
 * 
 * @export
 * @interface AnnualOrganizationSummary
 */
export interface AnnualOrganizationSummary {
    /**
     * 
     * @type {number}
     * @memberof AnnualOrganizationSummary
     */
    'organizationId': number;
    /**
     * 
     * @type {number}
     * @memberof AnnualOrganizationSummary
     */
    'year': number;
    /**
     * Annual emission amount by the organization
     * @type {number}
     * @memberof AnnualOrganizationSummary
     */
    'quantity': number;
    /**
     * Annual emissions per scopes
     * @type {Array<ScopeSummaryItem>}
     * @memberof AnnualOrganizationSummary
     */
    'scopes': Array<ScopeSummaryItem>;
    /**
     * Annual emissions per site
     * @type {Array<SiteSummaryItem>}
     * @memberof AnnualOrganizationSummary
     */
    'sites': Array<SiteSummaryItem>;
    /**
     * Monthly emission summaries in this year
     * @type {Array<MonthlyOrganizationSummary>}
     * @memberof AnnualOrganizationSummary
     */
    'months': Array<MonthlyOrganizationSummary>;
}
/**
 * 
 * @export
 * @interface AnnualSiteSummary
 */
export interface AnnualSiteSummary {
    /**
     * 
     * @type {number}
     * @memberof AnnualSiteSummary
     */
    'siteId': number;
    /**
     * 
     * @type {number}
     * @memberof AnnualSiteSummary
     */
    'year': number;
    /**
     * Annual emission amount by the site
     * @type {number}
     * @memberof AnnualSiteSummary
     */
    'quantity': number;
    /**
     * Annual emissions per scopes
     * @type {Array<ScopeSummaryItem>}
     * @memberof AnnualSiteSummary
     */
    'scopes': Array<ScopeSummaryItem>;
    /**
     * Monthly emission summaries in this year
     * @type {Array<MonthlySiteSummary>}
     * @memberof AnnualSiteSummary
     */
    'months': Array<MonthlySiteSummary>;
}
/**
 * 
 * @export
 * @interface AntiWarming
 */
export interface AntiWarming {
    /**
     * 
     * @type {number}
     * @memberof AntiWarming
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AntiWarming
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AntiWarmingOriginate
 */
export interface AntiWarmingOriginate {
    /**
     * 
     * @type {number}
     * @memberof AntiWarmingOriginate
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AntiWarmingOriginate
     */
    'site_id': number;
    /**
     * 
     * @type {number}
     * @memberof AntiWarmingOriginate
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof AntiWarmingOriginate
     */
    'number_emission': number;
}
/**
 * 
 * @export
 * @interface AntiWarmingOriginateCreate
 */
export interface AntiWarmingOriginateCreate {
    /**
     * 
     * @type {number}
     * @memberof AntiWarmingOriginateCreate
     */
    'year': number;
    /**
     * 
     * @type {Array<AntiWarmingOriginateCreateArr>}
     * @memberof AntiWarmingOriginateCreate
     */
    'data': Array<AntiWarmingOriginateCreateArr>;
}
/**
 * 
 * @export
 * @interface AntiWarmingOriginateCreateArr
 */
export interface AntiWarmingOriginateCreateArr {
    /**
     * 
     * @type {number}
     * @memberof AntiWarmingOriginateCreateArr
     */
    'site_id': number;
    /**
     * 
     * @type {number}
     * @memberof AntiWarmingOriginateCreateArr
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface AntiWarmingReport
 */
export interface AntiWarmingReport {
    /**
     * 
     * @type {number}
     * @memberof AntiWarmingReport
     */
    'site_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AntiWarmingReport
     */
    'site_name'?: string;
    /**
     * 
     * @type {Array<AntiWarmingReportList>}
     * @memberof AntiWarmingReport
     */
    'data'?: Array<AntiWarmingReportList>;
}
/**
 * 
 * @export
 * @interface AntiWarmingReportList
 */
export interface AntiWarmingReportList {
    /**
     * 
     * @type {number}
     * @memberof AntiWarmingReportList
     */
    'anti_warming_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AntiWarmingReportList
     */
    'anti_warming_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AntiWarmingReportList
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface CalculationMethodTreeNode
 */
export interface CalculationMethodTreeNode {
    /**
     * 
     * @type {number}
     * @memberof CalculationMethodTreeNode
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculationMethodTreeNode
     */
    'emissionFactorTableId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CalculationMethodTreeNode
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationMethodTreeNode
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CalculationMethodTreeNode
     */
    'showInfo'?: boolean;
    /**
     * 
     * @type {TreeNodeLink}
     * @memberof CalculationMethodTreeNode
     */
    'link'?: TreeNodeLink;
    /**
     * 
     * @type {Array<TreeNodeLink>}
     * @memberof CalculationMethodTreeNode
     */
    'modalLinks'?: Array<TreeNodeLink>;
    /**
     * 
     * @type {Array<CalculationMethodTreeNode>}
     * @memberof CalculationMethodTreeNode
     */
    'children'?: Array<CalculationMethodTreeNode>;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    'scopeId': number;
}
/**
 * 
 * @export
 * @interface CategoryEmissionFactorTable
 */
export interface CategoryEmissionFactorTable {
    /**
     * 
     * @type {number}
     * @memberof CategoryEmissionFactorTable
     */
    'category_emission_factor_table_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryEmissionFactorTable
     */
    'category_id': number;
}
/**
 * 
 * @export
 * @interface CategoryList
 */
export interface CategoryList {
    /**
     * 
     * @type {Array<Category>}
     * @memberof CategoryList
     */
    'data'?: Array<Category>;
    /**
     * 
     * @type {number}
     * @memberof CategoryList
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface CategorySummaryItem
 */
export interface CategorySummaryItem {
    /**
     * Category ID
     * @type {number}
     * @memberof CategorySummaryItem
     */
    'id': number;
    /**
     * Category name
     * @type {string}
     * @memberof CategorySummaryItem
     */
    'name': string;
    /**
     * Total emission amount of the category
     * @type {number}
     * @memberof CategorySummaryItem
     */
    'quantity': number;
    /**
     * Top 10 critical emission factors
     * @type {Array<EmissionFactorSummaryItem>}
     * @memberof CategorySummaryItem
     */
    'highestEmissionFactors'?: Array<EmissionFactorSummaryItem>;
    /**
     * Top emission factor tables
     * @type {Array<EmissionFactorTableSummaryItem>}
     * @memberof CategorySummaryItem
     */
    'highestEmissionFactorTables'?: Array<EmissionFactorTableSummaryItem>;
    /**
     * List emission factor tables
     * @type {Array<EmissionFactorTableSummaryItem>}
     * @memberof CategorySummaryItem
     */
    'emissionFactorTables'?: Array<EmissionFactorTableSummaryItem>;
}
/**
 * 
 * @export
 * @interface EmissionFactor
 */
export interface EmissionFactor {
    /**
     * 排出源単位ID
     * @type {number}
     * @memberof EmissionFactor
     */
    'id': number;
    /**
     * 排出係数テーブル ID
     * @type {number}
     * @memberof EmissionFactor
     */
    'emission_factor_table_id'?: number;
    /**
     * 排出源単位名
     * @type {string}
     * @memberof EmissionFactor
     */
    'name': string;
    /**
     * 温対法
     * @type {number}
     * @memberof EmissionFactor
     */
    'anti_warming_id'?: number;
    /**
     * 省エネ法
     * @type {number}
     * @memberof EmissionFactor
     */
    'energy_saving_id'?: number;
    /**
     * 排出係数
     * @type {number}
     * @memberof EmissionFactor
     */
    'value': number;
    /**
     * 排出係数の単位
     * @type {string}
     * @memberof EmissionFactor
     */
    'unit': string;
    /**
     * 年度
     * @type {number}
     * @memberof EmissionFactor
     */
    'year'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmissionFactor
     */
    'anti_warming_name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmissionFactor
     */
    'energy_saving_parent'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EmissionFactorCreate
 */
export interface EmissionFactorCreate {
    /**
     * 排出係数テーブル ID
     * @type {number}
     * @memberof EmissionFactorCreate
     */
    'emission_factor_table_id': number;
    /**
     * 排出源単位名
     * @type {string}
     * @memberof EmissionFactorCreate
     */
    'name': string;
    /**
     * 温対法
     * @type {number}
     * @memberof EmissionFactorCreate
     */
    'anti_warming_id'?: number;
    /**
     * 省エネ法
     * @type {number}
     * @memberof EmissionFactorCreate
     */
    'energy_saving_id'?: number;
    /**
     * 排出係数
     * @type {number}
     * @memberof EmissionFactorCreate
     */
    'value': number;
    /**
     * 排出係数の単位
     * @type {string}
     * @memberof EmissionFactorCreate
     */
    'unit': string;
    /**
     * 年度
     * @type {number}
     * @memberof EmissionFactorCreate
     */
    'year': number;
}
/**
 * 
 * @export
 * @interface EmissionFactorList
 */
export interface EmissionFactorList {
    /**
     * 
     * @type {Array<EmissionFactor>}
     * @memberof EmissionFactorList
     */
    'data'?: Array<EmissionFactor>;
    /**
     * 
     * @type {number}
     * @memberof EmissionFactorList
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface EmissionFactorSummaryItem
 */
export interface EmissionFactorSummaryItem {
    /**
     * Emission factor ID
     * @type {number}
     * @memberof EmissionFactorSummaryItem
     */
    'id': number;
    /**
     * Emission factor name
     * @type {string}
     * @memberof EmissionFactorSummaryItem
     */
    'name': string;
    /**
     * Emission amount
     * @type {number}
     * @memberof EmissionFactorSummaryItem
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface EmissionFactorTable
 */
export interface EmissionFactorTable {
    /**
     * 排出源単位テーブルID
     * @type {number}
     * @memberof EmissionFactorTable
     */
    'id': number;
    /**
     * 排出源単位テーブル名
     * @type {string}
     * @memberof EmissionFactorTable
     */
    'name': string;
    /**
     * ID順にソートされた排出原単位の集合
     * @type {Array<EmissionFactor>}
     * @memberof EmissionFactorTable
     */
    'emissionFactors'?: Array<EmissionFactor>;
    /**
     * 
     * @type {Array<CategoryEmissionFactorTable>}
     * @memberof EmissionFactorTable
     */
    'categories'?: Array<CategoryEmissionFactorTable>;
}
/**
 * 
 * @export
 * @interface EmissionFactorTableCreate
 */
export interface EmissionFactorTableCreate {
    /**
     * 
     * @type {Array<CategoryEmissionFactorTable>}
     * @memberof EmissionFactorTableCreate
     */
    'categories': Array<CategoryEmissionFactorTable>;
    /**
     * 排出源単位テーブル名
     * @type {string}
     * @memberof EmissionFactorTableCreate
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EmissionFactorTableList
 */
export interface EmissionFactorTableList {
    /**
     * 
     * @type {Array<EmissionFactorTable>}
     * @memberof EmissionFactorTableList
     */
    'data'?: Array<EmissionFactorTable>;
    /**
     * 
     * @type {number}
     * @memberof EmissionFactorTableList
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface EmissionFactorTableNode
 */
export interface EmissionFactorTableNode {
    /**
     * 
     * @type {number}
     * @memberof EmissionFactorTableNode
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmissionFactorTableNode
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof EmissionFactorTableNode
     */
    'emissionFactorTableId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionFactorTableNode
     */
    'categoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionFactorTableNode
     */
    'scopeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmissionFactorTableNode
     */
    'parent'?: string;
}
/**
 * 
 * @export
 * @interface EmissionFactorTableNodeList
 */
export interface EmissionFactorTableNodeList {
    /**
     * 
     * @type {Array<EmissionFactorTableNode>}
     * @memberof EmissionFactorTableNodeList
     */
    'data'?: Array<EmissionFactorTableNode>;
}
/**
 * 
 * @export
 * @interface EmissionFactorTableSummaryItem
 */
export interface EmissionFactorTableSummaryItem {
    /**
     * Emission factor table ID
     * @type {number}
     * @memberof EmissionFactorTableSummaryItem
     */
    'id': number;
    /**
     * Category emission factor table ID
     * @type {number}
     * @memberof EmissionFactorTableSummaryItem
     */
    'categoryEmissionFactorTableId'?: number;
    /**
     * Emission factor table name
     * @type {string}
     * @memberof EmissionFactorTableSummaryItem
     */
    'name': string;
    /**
     * Emission amount
     * @type {number}
     * @memberof EmissionFactorTableSummaryItem
     */
    'quantity': number;
    /**
     * List emission factors
     * @type {Array<EmissionFactorSummaryItem>}
     * @memberof EmissionFactorTableSummaryItem
     */
    'emissionFactors'?: Array<EmissionFactorSummaryItem>;
}
/**
 * 
 * @export
 * @interface EmissionFactorTableUpdate
 */
export interface EmissionFactorTableUpdate {
    /**
     * 
     * @type {Array<CategoryEmissionFactorTable>}
     * @memberof EmissionFactorTableUpdate
     */
    'categories': Array<CategoryEmissionFactorTable>;
    /**
     * 排出源単位テーブル名
     * @type {string}
     * @memberof EmissionFactorTableUpdate
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EmissionFactorUpdate
 */
export interface EmissionFactorUpdate {
    /**
     * 排出係数テーブル ID
     * @type {number}
     * @memberof EmissionFactorUpdate
     */
    'emission_factor_table_id': number;
    /**
     * 排出源単位名
     * @type {string}
     * @memberof EmissionFactorUpdate
     */
    'name': string;
    /**
     * 温対法
     * @type {number}
     * @memberof EmissionFactorUpdate
     */
    'anti_warming_id'?: number;
    /**
     * 省エネ法
     * @type {number}
     * @memberof EmissionFactorUpdate
     */
    'energy_saving_id'?: number;
    /**
     * 排出係数
     * @type {number}
     * @memberof EmissionFactorUpdate
     */
    'value': number;
    /**
     * 排出係数の単位
     * @type {string}
     * @memberof EmissionFactorUpdate
     */
    'unit': string;
    /**
     * 年度
     * @type {number}
     * @memberof EmissionFactorUpdate
     */
    'year': number;
}
/**
 * 
 * @export
 * @interface EmissionFactorsArr
 */
export interface EmissionFactorsArr {
    /**
     * 
     * @type {number}
     * @memberof EmissionFactorsArr
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmissionFactorsArr
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmissionFactorsArr
     */
    'unit'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmissionFactorsArr
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface EnergySaving
 */
export interface EnergySaving {
    /**
     * 
     * @type {Array<EnergySavingScope>}
     * @memberof EnergySaving
     */
    'scope1': Array<EnergySavingScope>;
    /**
     * 
     * @type {Array<EnergySavingScope>}
     * @memberof EnergySaving
     */
    'scope2': Array<EnergySavingScope>;
}
/**
 * 
 * @export
 * @interface EnergySavingLevel
 */
export interface EnergySavingLevel {
    /**
     * 
     * @type {number}
     * @memberof EnergySavingLevel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnergySavingLevel
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingLevel
     */
    'gj_coefficient'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnergySavingLevel
     */
    'gj_unit'?: string;
    /**
     * 
     * @type {Array<EnergySavingLevel>}
     * @memberof EnergySavingLevel
     */
    'children'?: Array<EnergySavingLevel>;
}
/**
 * 
 * @export
 * @interface EnergySavingList
 */
export interface EnergySavingList {
    /**
     * 
     * @type {number}
     * @memberof EnergySavingList
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnergySavingList
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface EnergySavingOriginate
 */
export interface EnergySavingOriginate {
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginate
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginate
     */
    'energy_saving_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginate
     */
    'site_id': number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginate
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginate
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginate
     */
    'number_emission': number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginate
     */
    'heat_quantity': number;
}
/**
 * 
 * @export
 * @interface EnergySavingOriginateCreate
 */
export interface EnergySavingOriginateCreate {
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginateCreate
     */
    'year': number;
    /**
     * 
     * @type {Array<EnergySavingOriginateCreateArr>}
     * @memberof EnergySavingOriginateCreate
     */
    'data': Array<EnergySavingOriginateCreateArr>;
}
/**
 * 
 * @export
 * @interface EnergySavingOriginateCreateArr
 */
export interface EnergySavingOriginateCreateArr {
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginateCreateArr
     */
    'energy_saving_id': number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginateCreateArr
     */
    'site_id': number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginateCreateArr
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginateCreateArr
     */
    'number_emission'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingOriginateCreateArr
     */
    'heat_quantity'?: number;
}
/**
 * 
 * @export
 * @interface EnergySavingReport
 */
export interface EnergySavingReport {
    /**
     * 
     * @type {number}
     * @memberof EnergySavingReport
     */
    'site_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnergySavingReport
     */
    'site_name'?: string;
    /**
     * 
     * @type {Array<EnergySavingReportList>}
     * @memberof EnergySavingReport
     */
    'data'?: Array<EnergySavingReportList>;
}
/**
 * 
 * @export
 * @interface EnergySavingReportList
 */
export interface EnergySavingReportList {
    /**
     * 
     * @type {number}
     * @memberof EnergySavingReportList
     */
    'emission_factor_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingReportList
     */
    'energy_saving_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnergySavingReportList
     */
    'energy_saving_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingReportList
     */
    'activity'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingReportList
     */
    'emission_number'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingReportList
     */
    'amount_heat'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergySavingReportList
     */
    'coefficient'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnergySavingReportList
     */
    'unit'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EnergySavingReportList
     */
    'inputtable'?: boolean;
}
/**
 * 
 * @export
 * @interface EnergySavingScope
 */
export interface EnergySavingScope {
    /**
     * 
     * @type {string}
     * @memberof EnergySavingScope
     */
    'name'?: string;
    /**
     * 
     * @type {Array<EnergySavingList>}
     * @memberof EnergySavingScope
     */
    'types'?: Array<EnergySavingList>;
}
/**
 * Error
 * @export
 * @interface ErrorItem
 */
export interface ErrorItem {
    /**
     * Message
     * @type {string}
     * @memberof ErrorItem
     */
    'message': string;
}
/**
 * Errors
 * @export
 * @interface Errors
 */
export interface Errors {
    /**
     * 
     * @type {Array<ErrorItem>}
     * @memberof Errors
     */
    'errors': Array<ErrorItem>;
}
/**
 * 
 * @export
 * @interface GetSiteCategoryTransactionsRequest
 */
export interface GetSiteCategoryTransactionsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSiteCategoryTransactionsRequest
     */
    'categoryIdList'?: Array<number>;
}
/**
 * 
 * @export
 * @interface HalfYearOrganizationSummary
 */
export interface HalfYearOrganizationSummary {
    /**
     * 
     * @type {number}
     * @memberof HalfYearOrganizationSummary
     */
    'organizationId': number;
    /**
     * 
     * @type {number}
     * @memberof HalfYearOrganizationSummary
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof HalfYearOrganizationSummary
     */
    'half': number;
    /**
     * Annual emission amount by the organization
     * @type {number}
     * @memberof HalfYearOrganizationSummary
     */
    'quantity': number;
    /**
     * Annual emissions per scopes
     * @type {Array<ScopeSummaryItem>}
     * @memberof HalfYearOrganizationSummary
     */
    'scopes': Array<ScopeSummaryItem>;
    /**
     * Annual emissions per site
     * @type {Array<SiteSummaryItem>}
     * @memberof HalfYearOrganizationSummary
     */
    'sites': Array<SiteSummaryItem>;
    /**
     * Monthly emission summaries in this half year
     * @type {Array<MonthlyOrganizationSummary>}
     * @memberof HalfYearOrganizationSummary
     */
    'months': Array<MonthlyOrganizationSummary>;
}
/**
 * 
 * @export
 * @interface HalfYearSiteSummary
 */
export interface HalfYearSiteSummary {
    /**
     * 
     * @type {number}
     * @memberof HalfYearSiteSummary
     */
    'siteId': number;
    /**
     * 
     * @type {number}
     * @memberof HalfYearSiteSummary
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof HalfYearSiteSummary
     */
    'half': number;
    /**
     * Annual emission amount by the site
     * @type {number}
     * @memberof HalfYearSiteSummary
     */
    'quantity': number;
    /**
     * Annual emissions per scopes
     * @type {Array<ScopeSummaryItem>}
     * @memberof HalfYearSiteSummary
     */
    'scopes': Array<ScopeSummaryItem>;
    /**
     * Monthly emission summaries in this year
     * @type {Array<MonthlySiteSummary>}
     * @memberof HalfYearSiteSummary
     */
    'months': Array<MonthlySiteSummary>;
}
/**
 * 
 * @export
 * @interface IdeaVersion
 */
export interface IdeaVersion {
    /**
     * 
     * @type {IdeaVersionId}
     * @memberof IdeaVersion
     */
    'id': IdeaVersionId;
    /**
     * 
     * @type {string}
     * @memberof IdeaVersion
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface IdeaVersionId
 */
export interface IdeaVersionId {
    /**
     * 
     * @type {number}
     * @memberof IdeaVersionId
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface IdeaVersionList
 */
export interface IdeaVersionList {
    /**
     * 
     * @type {Array<IdeaVersion>}
     * @memberof IdeaVersionList
     */
    'data'?: Array<IdeaVersion>;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface MonthOrganizationSummary
 */
export interface MonthOrganizationSummary {
    /**
     * 
     * @type {number}
     * @memberof MonthOrganizationSummary
     */
    'organizationId': number;
    /**
     * Total emission amount by the organization
     * @type {number}
     * @memberof MonthOrganizationSummary
     */
    'quantity': number;
    /**
     * 
     * @type {Array<MonthSummaryItem>}
     * @memberof MonthOrganizationSummary
     */
    'months': Array<MonthSummaryItem>;
}
/**
 * 
 * @export
 * @interface MonthSiteSummary
 */
export interface MonthSiteSummary {
    /**
     * 
     * @type {number}
     * @memberof MonthSiteSummary
     */
    'siteId': number;
    /**
     * Total emission amount by the organization
     * @type {number}
     * @memberof MonthSiteSummary
     */
    'quantity': number;
    /**
     * 
     * @type {Array<MonthSummaryItem>}
     * @memberof MonthSiteSummary
     */
    'months': Array<MonthSummaryItem>;
}
/**
 * 
 * @export
 * @interface MonthSummaryItem
 */
export interface MonthSummaryItem {
    /**
     * 月
     * @type {string}
     * @memberof MonthSummaryItem
     */
    'month': string;
    /**
     * emission amount on the year
     * @type {number}
     * @memberof MonthSummaryItem
     */
    'quantity': number;
    /**
     * emissions per scopes on the year
     * @type {Array<ScopeSummaryItem>}
     * @memberof MonthSummaryItem
     */
    'scopes': Array<ScopeSummaryItem>;
}
/**
 * 
 * @export
 * @interface MonthlyOrganizationSummary
 */
export interface MonthlyOrganizationSummary {
    /**
     * 
     * @type {number}
     * @memberof MonthlyOrganizationSummary
     */
    'organizationId': number;
    /**
     * 月
     * @type {string}
     * @memberof MonthlyOrganizationSummary
     */
    'month': string;
    /**
     * Monthly emission amount by the organization
     * @type {number}
     * @memberof MonthlyOrganizationSummary
     */
    'quantity': number;
    /**
     * Monthly emissions per scopes
     * @type {Array<ScopeSummaryItem>}
     * @memberof MonthlyOrganizationSummary
     */
    'scopes': Array<ScopeSummaryItem>;
    /**
     * Total emission amount per site
     * @type {Array<SiteSummaryItem>}
     * @memberof MonthlyOrganizationSummary
     */
    'sites': Array<SiteSummaryItem>;
}
/**
 * 
 * @export
 * @interface MonthlySiteSummary
 */
export interface MonthlySiteSummary {
    /**
     * 
     * @type {number}
     * @memberof MonthlySiteSummary
     */
    'siteId': number;
    /**
     * 月
     * @type {string}
     * @memberof MonthlySiteSummary
     */
    'month': string;
    /**
     * Monthly emission amount by the site
     * @type {number}
     * @memberof MonthlySiteSummary
     */
    'quantity': number;
    /**
     * Monthly emissions per scopes
     * @type {Array<ScopeSummaryItem>}
     * @memberof MonthlySiteSummary
     */
    'scopes': Array<ScopeSummaryItem>;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'updatedDate'?: string;
}
/**
 * 
 * @export
 * @interface NotificationAttr
 */
export interface NotificationAttr {
    /**
     * 
     * @type {string}
     * @memberof NotificationAttr
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAttr
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof NotificationAttr
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationAttr
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAttr
     */
    'updatedDate'?: string;
}
/**
 * 
 * @export
 * @interface NotificationCreate
 */
export interface NotificationCreate {
    /**
     * 
     * @type {string}
     * @memberof NotificationCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCreate
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface NotificationId
 */
export interface NotificationId {
    /**
     * 
     * @type {number}
     * @memberof NotificationId
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface NotificationList
 */
export interface NotificationList {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationList
     */
    'data'?: Array<Notification>;
    /**
     * 
     * @type {number}
     * @memberof NotificationList
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface NotificationNew
 */
export interface NotificationNew {
    /**
     * 
     * @type {number}
     * @memberof NotificationNew
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface NotificationStatusUpdate
 */
export interface NotificationStatusUpdate {
    /**
     * 
     * @type {number}
     * @memberof NotificationStatusUpdate
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationStatusUpdate
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface NotificationUpdate
 */
export interface NotificationUpdate {
    /**
     * 
     * @type {string}
     * @memberof NotificationUpdate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationUpdate
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface Objective
 */
export interface Objective {
    /**
     * 組織目標（現在は単なる文字列）
     * @type {string}
     * @memberof Objective
     */
    'text': string;
    /**
     * 年度
     * @type {number}
     * @memberof Objective
     */
    'year': number;
}
/**
 * 
 * @export
 * @interface Objectives
 */
export interface Objectives {
    /**
     * 組織目標の年度ごとのリスト
     * @type {Array<Objective>}
     * @memberof Objectives
     */
    'objectives': Array<Objective>;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'startMonth': number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'planId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'planName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'status'?: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'baseNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'siteNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'userNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'updatedDate'?: string;
}
/**
 * 
 * @export
 * @interface OrganizationAttr
 */
export interface OrganizationAttr {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAttr
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationAttr
     */
    'startMonth': number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationAttr
     */
    'planId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAttr
     */
    'planName'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationAttr
     */
    'status'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationAttr
     */
    'baseNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationAttr
     */
    'siteNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationAttr
     */
    'userNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAttr
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAttr
     */
    'updatedDate'?: string;
}
/**
 * 
 * @export
 * @interface OrganizationCreate
 */
export interface OrganizationCreate {
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationCreate
     */
    'startMonth': number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationCreate
     */
    'planId': number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationCreate
     */
    'status'?: number;
    /**
     * 
     * @type {Array<UserCreate>}
     * @memberof OrganizationCreate
     */
    'users'?: Array<UserCreate>;
    /**
     * 
     * @type {Array<SiteCreate>}
     * @memberof OrganizationCreate
     */
    'sites'?: Array<SiteCreate>;
}
/**
 * 
 * @export
 * @interface OrganizationId
 */
export interface OrganizationId {
    /**
     * 
     * @type {number}
     * @memberof OrganizationId
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface OrganizationIdeaVersion
 */
export interface OrganizationIdeaVersion {
    /**
     * 
     * @type {OrganizationIdeaVersionId}
     * @memberof OrganizationIdeaVersion
     */
    'id': OrganizationIdeaVersionId;
    /**
     * 
     * @type {string}
     * @memberof OrganizationIdeaVersion
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationIdeaVersion
     */
    'ideaVersionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationIdeaVersion
     */
    'updateUserName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationIdeaVersion
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationIdeaVersion
     */
    'updatedDate'?: string;
}
/**
 * 
 * @export
 * @interface OrganizationIdeaVersionId
 */
export interface OrganizationIdeaVersionId {
    /**
     * 
     * @type {number}
     * @memberof OrganizationIdeaVersionId
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface OrganizationIdeaVersionList
 */
export interface OrganizationIdeaVersionList {
    /**
     * 
     * @type {Array<OrganizationIdeaVersion>}
     * @memberof OrganizationIdeaVersionList
     */
    'data'?: Array<OrganizationIdeaVersion>;
}
/**
 * 
 * @export
 * @interface OrganizationList
 */
export interface OrganizationList {
    /**
     * 
     * @type {Array<Organization>}
     * @memberof OrganizationList
     */
    'data'?: Array<Organization>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationList
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface OrganizationName
 */
export interface OrganizationName {
    /**
     * 
     * @type {string}
     * @memberof OrganizationName
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OrganizationProfileUpdate
 */
export interface OrganizationProfileUpdate {
    /**
     * 
     * @type {string}
     * @memberof OrganizationProfileUpdate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationProfileUpdate
     */
    'startMonth': number;
    /**
     * 
     * @type {Array<OrganizationSite>}
     * @memberof OrganizationProfileUpdate
     */
    'sites'?: Array<OrganizationSite>;
}
/**
 * 
 * @export
 * @interface OrganizationSite
 */
export interface OrganizationSite {
    /**
     * 
     * @type {number}
     * @memberof OrganizationSite
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSite
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OrganizationStartMonth
 */
export interface OrganizationStartMonth {
    /**
     * 
     * @type {number}
     * @memberof OrganizationStartMonth
     */
    'startMonth': number;
}
/**
 * 
 * @export
 * @interface OrganizationUpdate
 */
export interface OrganizationUpdate {
    /**
     * 
     * @type {string}
     * @memberof OrganizationUpdate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationUpdate
     */
    'startMonth': number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationUpdate
     */
    'planId': number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationUpdate
     */
    'status'?: number;
    /**
     * 
     * @type {Array<OrganizationSite>}
     * @memberof OrganizationUpdate
     */
    'sites'?: Array<OrganizationSite>;
}
/**
 * 
 * @export
 * @interface QuarterlyOrganizationSummary
 */
export interface QuarterlyOrganizationSummary {
    /**
     * 
     * @type {number}
     * @memberof QuarterlyOrganizationSummary
     */
    'organizationId': number;
    /**
     * 
     * @type {number}
     * @memberof QuarterlyOrganizationSummary
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof QuarterlyOrganizationSummary
     */
    'quarter': number;
    /**
     * Annual emission amount by the organization
     * @type {number}
     * @memberof QuarterlyOrganizationSummary
     */
    'quantity': number;
    /**
     * Annual emissions per scopes
     * @type {Array<ScopeSummaryItem>}
     * @memberof QuarterlyOrganizationSummary
     */
    'scopes': Array<ScopeSummaryItem>;
    /**
     * Annual emissions per site
     * @type {Array<SiteSummaryItem>}
     * @memberof QuarterlyOrganizationSummary
     */
    'sites': Array<SiteSummaryItem>;
    /**
     * Monthly emission summaries in this quarter
     * @type {Array<MonthlyOrganizationSummary>}
     * @memberof QuarterlyOrganizationSummary
     */
    'months': Array<MonthlyOrganizationSummary>;
}
/**
 * 
 * @export
 * @interface QuarterlySiteSummary
 */
export interface QuarterlySiteSummary {
    /**
     * 
     * @type {number}
     * @memberof QuarterlySiteSummary
     */
    'siteId': number;
    /**
     * 
     * @type {number}
     * @memberof QuarterlySiteSummary
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof QuarterlySiteSummary
     */
    'quarter': number;
    /**
     * Annual emission amount by the site
     * @type {number}
     * @memberof QuarterlySiteSummary
     */
    'quantity': number;
    /**
     * Annual emissions per scopes
     * @type {Array<ScopeSummaryItem>}
     * @memberof QuarterlySiteSummary
     */
    'scopes': Array<ScopeSummaryItem>;
    /**
     * Monthly emission summaries in this year
     * @type {Array<MonthlySiteSummary>}
     * @memberof QuarterlySiteSummary
     */
    'months': Array<MonthlySiteSummary>;
}
/**
 * 
 * @export
 * @interface ReportFilter
 */
export interface ReportFilter {
    /**
     * 
     * @type {number}
     * @memberof ReportFilter
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportFilter
     */
    'baseId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportFilter
     */
    'categoryEmissionFactorTables'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ReportList
 */
export interface ReportList {
    /**
     * 
     * @type {Array<SitesArr>}
     * @memberof ReportList
     */
    'sites'?: Array<SitesArr>;
}
/**
 * 
 * @export
 * @interface SaveActivities
 */
export interface SaveActivities {
    /**
     * 
     * @type {Array<SaveActivity>}
     * @memberof SaveActivities
     */
    'activities': Array<SaveActivity>;
}
/**
 * 
 * @export
 * @interface SaveActivity
 */
export interface SaveActivity {
    /**
     * Activity ID
     * @type {number}
     * @memberof SaveActivity
     */
    'id'?: number;
    /**
     * Category emission factor table ID
     * @type {number}
     * @memberof SaveActivity
     */
    'categoryEmissionFactorTableId'?: number;
    /**
     * Emission factor ID
     * @type {number}
     * @memberof SaveActivity
     */
    'emissionFactorId'?: number;
    /**
     * Activity amount
     * @type {number}
     * @memberof SaveActivity
     */
    'quantity'?: number;
    /**
     * 月
     * @type {string}
     * @memberof SaveActivity
     */
    'month'?: string;
    /**
     * Delete activity flag
     * @type {number}
     * @memberof SaveActivity
     */
    'deleteFlg'?: number;
    /**
     * Memo for each activity
     * @type {string}
     * @memberof SaveActivity
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface SaveSiteCategoryTransactionRequest
 */
export interface SaveSiteCategoryTransactionRequest {
    /**
     * Site Category Transaction ID
     * @type {number}
     * @memberof SaveSiteCategoryTransactionRequest
     */
    'id'?: number;
    /**
     * Category emission factor table ID
     * @type {number}
     * @memberof SaveSiteCategoryTransactionRequest
     */
    'categoryEmissionFactorTableId'?: number;
    /**
     * 月
     * @type {string}
     * @memberof SaveSiteCategoryTransactionRequest
     */
    'month'?: string;
    /**
     * Memo for each Site Category Transaction
     * @type {string}
     * @memberof SaveSiteCategoryTransactionRequest
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface Scope
 */
export interface Scope {
    /**
     * 
     * @type {number}
     * @memberof Scope
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Scope
     */
    'name': string;
    /**
     * 
     * @type {Array<ScopeCategory>}
     * @memberof Scope
     */
    'categories': Array<ScopeCategory>;
}
/**
 * 
 * @export
 * @interface ScopeCategory
 */
export interface ScopeCategory {
    /**
     * 
     * @type {number}
     * @memberof ScopeCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ScopeCategory
     */
    'name': string;
    /**
     * 
     * @type {CalculationMethodTreeNode}
     * @memberof ScopeCategory
     */
    'calculationMethodTree'?: CalculationMethodTreeNode;
}
/**
 * 
 * @export
 * @interface ScopeSummaryItem
 */
export interface ScopeSummaryItem {
    /**
     * Scope ID
     * @type {number}
     * @memberof ScopeSummaryItem
     */
    'id': number;
    /**
     * Total emission amount of the scope
     * @type {number}
     * @memberof ScopeSummaryItem
     */
    'quantity': number;
    /**
     * 
     * @type {Array<CategorySummaryItem>}
     * @memberof ScopeSummaryItem
     */
    'categories': Array<CategorySummaryItem>;
}
/**
 * 
 * @export
 * @interface Scopes
 */
export interface Scopes {
    /**
     * 
     * @type {Array<Scope>}
     * @memberof Scopes
     */
    'scopes': Array<Scope>;
}
/**
 * 
 * @export
 * @interface Site
 */
export interface Site {
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    'organizationId': number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    'parentSiteId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    'updatedDate'?: string;
}
/**
 * 
 * @export
 * @interface SiteAttr
 */
export interface SiteAttr {
    /**
     * 
     * @type {string}
     * @memberof SiteAttr
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SiteAttr
     */
    'organizationId': number;
    /**
     * 
     * @type {number}
     * @memberof SiteAttr
     */
    'parentSiteId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteAttr
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteAttr
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteAttr
     */
    'updatedDate'?: string;
}
/**
 * 
 * @export
 * @interface SiteCategoryTransaction
 */
export interface SiteCategoryTransaction {
    /**
     * Site Category Transaction ID
     * @type {number}
     * @memberof SiteCategoryTransaction
     */
    'id'?: number;
    /**
     * Category emission factor table ID
     * @type {number}
     * @memberof SiteCategoryTransaction
     */
    'categoryEmissionFactorTableId': number;
    /**
     * 月
     * @type {string}
     * @memberof SiteCategoryTransaction
     */
    'month'?: string;
    /**
     * Memo for each category
     * @type {string}
     * @memberof SiteCategoryTransaction
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface SiteCategoryTransactionAttr
 */
export interface SiteCategoryTransactionAttr {
    /**
     * Category emission factor table ID
     * @type {number}
     * @memberof SiteCategoryTransactionAttr
     */
    'categoryEmissionFactorTableId': number;
    /**
     * 月
     * @type {string}
     * @memberof SiteCategoryTransactionAttr
     */
    'month'?: string;
    /**
     * Memo for each category
     * @type {string}
     * @memberof SiteCategoryTransactionAttr
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface SiteCategoryTransactionId
 */
export interface SiteCategoryTransactionId {
    /**
     * Site Category Transaction ID
     * @type {number}
     * @memberof SiteCategoryTransactionId
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SiteCategoryTransactionList
 */
export interface SiteCategoryTransactionList {
    /**
     * 
     * @type {Array<SiteCategoryTransaction>}
     * @memberof SiteCategoryTransactionList
     */
    'siteCategoryTransactionList': Array<SiteCategoryTransaction>;
    /**
     * 
     * @type {number}
     * @memberof SiteCategoryTransactionList
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface SiteCreate
 */
export interface SiteCreate {
    /**
     * 
     * @type {string}
     * @memberof SiteCreate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SiteCreate
     */
    'parentSiteId'?: number;
}
/**
 * 
 * @export
 * @interface SiteId
 */
export interface SiteId {
    /**
     * 
     * @type {number}
     * @memberof SiteId
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SiteList
 */
export interface SiteList {
    /**
     * 
     * @type {Array<Site>}
     * @memberof SiteList
     */
    'data'?: Array<Site>;
    /**
     * 
     * @type {number}
     * @memberof SiteList
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface SiteNode
 */
export interface SiteNode {
    /**
     * 拠点ID
     * @type {number}
     * @memberof SiteNode
     */
    'siteId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteNode
     */
    'parentSiteId'?: number;
    /**
     * ノードラベル
     * @type {string}
     * @memberof SiteNode
     */
    'title': string;
    /**
     * 活動量が入力済みであるか。ノードの移動制限に利用する。
     * @type {boolean}
     * @memberof SiteNode
     */
    'isActivityEntered': boolean;
    /**
     * 活動量が入力済みであるか。ノードの移動制限に利用する。
     * @type {boolean}
     * @memberof SiteNode
     */
    'deleted': boolean;
    /**
     * 空の場合、当該ノードが末端となる。
     * @type {Array<SiteNode>}
     * @memberof SiteNode
     */
    'children': Array<SiteNode>;
}
/**
 * 
 * @export
 * @interface SiteNodeSave
 */
export interface SiteNodeSave {
    /**
     * 拠点ID
     * @type {number}
     * @memberof SiteNodeSave
     */
    'siteId'?: number;
    /**
     * 組織ID
     * @type {number}
     * @memberof SiteNodeSave
     */
    'organizationId': number;
    /**
     * 親拠点ID。対象のノードが第一階層の拠点の場合は、`null`を設定してください
     * @type {number}
     * @memberof SiteNodeSave
     */
    'parentSiteId'?: number;
    /**
     * 拠点名
     * @type {string}
     * @memberof SiteNodeSave
     */
    'name': string;
    /**
     * 空の場合、当該ノードが末端となる。
     * @type {Array<SiteNodeSave>}
     * @memberof SiteNodeSave
     */
    'children': Array<SiteNodeSave>;
}
/**
 * 
 * @export
 * @interface SiteNodes
 */
export interface SiteNodes {
    /**
     * 
     * @type {Array<SiteNode>}
     * @memberof SiteNodes
     */
    'siteNodes'?: Array<SiteNode>;
}
/**
 * 
 * @export
 * @interface SiteNodesSave
 */
export interface SiteNodesSave {
    /**
     * 保存対象の拠点ノードリスト全体
     * @type {Array<SiteNodeSave>}
     * @memberof SiteNodesSave
     */
    'siteNodes'?: Array<SiteNodeSave>;
}
/**
 * 
 * @export
 * @interface SiteObjective
 */
export interface SiteObjective {
    /**
     * 
     * @type {number}
     * @memberof SiteObjective
     */
    'siteId': number;
    /**
     * 
     * @type {Array<Objective>}
     * @memberof SiteObjective
     */
    'objectives': Array<Objective>;
}
/**
 * 
 * @export
 * @interface SiteObjectives
 */
export interface SiteObjectives {
    /**
     * 
     * @type {Array<SiteObjective>}
     * @memberof SiteObjectives
     */
    'sites': Array<SiteObjective>;
}
/**
 * 
 * @export
 * @interface SiteSummaryItem
 */
export interface SiteSummaryItem {
    /**
     * Site ID
     * @type {number}
     * @memberof SiteSummaryItem
     */
    'id': number;
    /**
     * Site name
     * @type {string}
     * @memberof SiteSummaryItem
     */
    'name': string;
    /**
     * Site emissions per scopes
     * @type {Array<ScopeSummaryItem>}
     * @memberof SiteSummaryItem
     */
    'scopes': Array<ScopeSummaryItem>;
    /**
     * Emission amount
     * @type {number}
     * @memberof SiteSummaryItem
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface SiteUpdate
 */
export interface SiteUpdate {
    /**
     * 
     * @type {string}
     * @memberof SiteUpdate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SiteUpdate
     */
    'status'?: number;
}
/**
 * 
 * @export
 * @interface SiteUpdateBatch
 */
export interface SiteUpdateBatch {
    /**
     * 
     * @type {number}
     * @memberof SiteUpdateBatch
     */
    'siteId': number;
    /**
     * 
     * @type {Objective}
     * @memberof SiteUpdateBatch
     */
    'objective': Objective;
}
/**
 * 
 * @export
 * @interface SitesArr
 */
export interface SitesArr {
    /**
     * 
     * @type {number}
     * @memberof SitesArr
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SitesArr
     */
    'name'?: string;
    /**
     * 
     * @type {Array<EmissionFactorsArr>}
     * @memberof SitesArr
     */
    'emissionFactors'?: Array<EmissionFactorsArr>;
}
/**
 * 
 * @export
 * @interface Submission
 */
export interface Submission {
    /**
     * 月
     * @type {string}
     * @memberof Submission
     */
    'month': string;
    /**
     * 
     * @type {SubmissionStatus}
     * @memberof Submission
     */
    'status': SubmissionStatus;
    /**
     * 入力済みのActivityが存在するカテゴリのリスト
     * @type {Array<SubmittedCategory>}
     * @memberof Submission
     */
    'categories': Array<SubmittedCategory>;
}
/**
 * 
 * @export
 * @interface SubmissionRequest
 */
export interface SubmissionRequest {
    /**
     * 月
     * @type {string}
     * @memberof SubmissionRequest
     */
    'month': string;
    /**
     * 
     * @type {SubmissionStatus}
     * @memberof SubmissionRequest
     */
    'status': SubmissionStatus;
}
/**
 * 入力状況
 * @export
 * @enum {string}
 */

export enum SubmissionStatus {
    InProgress = 'in progress',
    Done = 'done'
}

/**
 * 
 * @export
 * @interface Submissions
 */
export interface Submissions {
    /**
     * 月ごとの入力状況のリスト
     * @type {Array<Submission>}
     * @memberof Submissions
     */
    'submissions': Array<Submission>;
}
/**
 * 
 * @export
 * @interface SubmittedCalculationMethod
 */
export interface SubmittedCalculationMethod {
    /**
     * 算定方法ID (category emission factor table ID)
     * @type {number}
     * @memberof SubmittedCalculationMethod
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SubmittedCategory
 */
export interface SubmittedCategory {
    /**
     * カテゴリID
     * @type {number}
     * @memberof SubmittedCategory
     */
    'id': number;
    /**
     * 
     * @type {SubmissionStatus}
     * @memberof SubmittedCategory
     */
    'status': SubmissionStatus;
    /**
     * 入力済みのActivityが存在する算定方法のリスト
     * @type {Array<SubmittedCalculationMethod>}
     * @memberof SubmittedCategory
     */
    'calculationMethods': Array<SubmittedCalculationMethod>;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'refresh_token': string;
    /**
     * 
     * @type {number}
     * @memberof Token
     */
    'expiresIn'?: number;
}
/**
 * 
 * @export
 * @interface TreeNodeLink
 */
export interface TreeNodeLink {
    /**
     * 
     * @type {string}
     * @memberof TreeNodeLink
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof TreeNodeLink
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'roleName'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'organizationId': number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'siteRef'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'siteRefName'?: string;
    /**
     * 
     * @type {Array<Site>}
     * @memberof User
     */
    'userSites'?: Array<Site>;
}
/**
 * 
 * @export
 * @interface UserAttr
 */
export interface UserAttr {
    /**
     * 
     * @type {string}
     * @memberof UserAttr
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserAttr
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UserAttr
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof UserAttr
     */
    'roleName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserAttr
     */
    'organizationId': number;
    /**
     * 
     * @type {number}
     * @memberof UserAttr
     */
    'siteRef'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAttr
     */
    'siteRefName'?: string;
    /**
     * 
     * @type {Array<Site>}
     * @memberof UserAttr
     */
    'userSites'?: Array<Site>;
}
/**
 * 
 * @export
 * @interface UserChangeEmail
 */
export interface UserChangeEmail {
    /**
     * 
     * @type {string}
     * @memberof UserChangeEmail
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UserCreate
     */
    'roleId': number;
    /**
     * 
     * @type {number}
     * @memberof UserCreate
     */
    'siteRef'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserCreate
     */
    'userSites'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UserId
 */
export interface UserId {
    /**
     * 
     * @type {string}
     * @memberof UserId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserList
     */
    'data'?: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof UserList
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UserUpdate
     */
    'roleId': number;
    /**
     * 
     * @type {number}
     * @memberof UserUpdate
     */
    'siteRef'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserUpdate
     */
    'userSites'?: Array<number>;
}
/**
 * 
 * @export
 * @interface YearOrganizationSummary
 */
export interface YearOrganizationSummary {
    /**
     * 
     * @type {number}
     * @memberof YearOrganizationSummary
     */
    'organizationId': number;
    /**
     * Total emission amount by the organization
     * @type {number}
     * @memberof YearOrganizationSummary
     */
    'quantity': number;
    /**
     * 
     * @type {Array<YearSummaryItem>}
     * @memberof YearOrganizationSummary
     */
    'years': Array<YearSummaryItem>;
}
/**
 * 
 * @export
 * @interface YearSiteSummary
 */
export interface YearSiteSummary {
    /**
     * 
     * @type {number}
     * @memberof YearSiteSummary
     */
    'siteId': number;
    /**
     * Total emission amount by the organization
     * @type {number}
     * @memberof YearSiteSummary
     */
    'quantity': number;
    /**
     * 
     * @type {Array<YearSummaryItem>}
     * @memberof YearSiteSummary
     */
    'years': Array<YearSummaryItem>;
}
/**
 * 
 * @export
 * @interface YearSummaryItem
 */
export interface YearSummaryItem {
    /**
     * 年
     * @type {number}
     * @memberof YearSummaryItem
     */
    'year': number;
    /**
     * emission amount on the year
     * @type {number}
     * @memberof YearSummaryItem
     */
    'quantity': number;
    /**
     * emissions per scopes on the year
     * @type {Array<ScopeSummaryItem>}
     * @memberof YearSummaryItem
     */
    'scopes': Array<ScopeSummaryItem>;
}

/**
 * AntiWarmingApi - axios parameter creator
 * @export
 */
export const AntiWarmingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get list anti warming types 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAntiWarmingTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/antiWarming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AntiWarmingApi - functional programming interface
 * @export
 */
export const AntiWarmingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AntiWarmingApiAxiosParamCreator(configuration)
    return {
        /**
         * get list anti warming types 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAntiWarmingTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AntiWarming>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAntiWarmingTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AntiWarmingApi - factory interface
 * @export
 */
export const AntiWarmingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AntiWarmingApiFp(configuration)
    return {
        /**
         * get list anti warming types 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAntiWarmingTypes(options?: any): AxiosPromise<Array<AntiWarming>> {
            return localVarFp.getAntiWarmingTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AntiWarmingApi - object-oriented interface
 * @export
 * @class AntiWarmingApi
 * @extends {BaseAPI}
 */
export class AntiWarmingApi extends BaseAPI {
    /**
     * get list anti warming types 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntiWarmingApi
     */
    public getAntiWarmingTypes(options?: AxiosRequestConfig) {
        return AntiWarmingApiFp(this.configuration).getAntiWarmingTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AntiWarmingReportApi - axios parameter creator
 * @export
 */
export const AntiWarmingReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create/Update report anti-warming originate by site and year. 
         * @param {AntiWarmingOriginateCreate} [antiWarmingOriginateCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAntiWarmingOriginate: async (antiWarmingOriginateCreate?: AntiWarmingOriginateCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/antiWarmingReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(antiWarmingOriginateCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get report anti-warming by site 
         * @param {string} [search] Search
         * @param {number} [year] Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAntiWarmingReport: async (search?: string, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/antiWarmingReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AntiWarmingReportApi - functional programming interface
 * @export
 */
export const AntiWarmingReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AntiWarmingReportApiAxiosParamCreator(configuration)
    return {
        /**
         * Create/Update report anti-warming originate by site and year. 
         * @param {AntiWarmingOriginateCreate} [antiWarmingOriginateCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAntiWarmingOriginate(antiWarmingOriginateCreate?: AntiWarmingOriginateCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AntiWarmingOriginate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAntiWarmingOriginate(antiWarmingOriginateCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get report anti-warming by site 
         * @param {string} [search] Search
         * @param {number} [year] Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAntiWarmingReport(search?: string, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AntiWarmingReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAntiWarmingReport(search, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AntiWarmingReportApi - factory interface
 * @export
 */
export const AntiWarmingReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AntiWarmingReportApiFp(configuration)
    return {
        /**
         * Create/Update report anti-warming originate by site and year. 
         * @param {AntiWarmingOriginateCreate} [antiWarmingOriginateCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAntiWarmingOriginate(antiWarmingOriginateCreate?: AntiWarmingOriginateCreate, options?: any): AxiosPromise<Array<AntiWarmingOriginate>> {
            return localVarFp.createAntiWarmingOriginate(antiWarmingOriginateCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * get report anti-warming by site 
         * @param {string} [search] Search
         * @param {number} [year] Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAntiWarmingReport(search?: string, year?: number, options?: any): AxiosPromise<Array<AntiWarmingReport>> {
            return localVarFp.getAntiWarmingReport(search, year, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AntiWarmingReportApi - object-oriented interface
 * @export
 * @class AntiWarmingReportApi
 * @extends {BaseAPI}
 */
export class AntiWarmingReportApi extends BaseAPI {
    /**
     * Create/Update report anti-warming originate by site and year. 
     * @param {AntiWarmingOriginateCreate} [antiWarmingOriginateCreate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntiWarmingReportApi
     */
    public createAntiWarmingOriginate(antiWarmingOriginateCreate?: AntiWarmingOriginateCreate, options?: AxiosRequestConfig) {
        return AntiWarmingReportApiFp(this.configuration).createAntiWarmingOriginate(antiWarmingOriginateCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get report anti-warming by site 
     * @param {string} [search] Search
     * @param {number} [year] Year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntiWarmingReportApi
     */
    public getAntiWarmingReport(search?: string, year?: number, options?: AxiosRequestConfig) {
        return AntiWarmingReportApiFp(this.configuration).getAntiWarmingReport(search, year, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthAPIApi - axios parameter creator
 * @export
 */
export const AuthAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Login user.  ログイン管理者。 
         * @param {Login} [login] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (login?: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Login admin.  ログイン管理者。 
         * @param {Login} [login] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAdmin: async (login?: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/admin/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthAPIApi - functional programming interface
 * @export
 */
export const AuthAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Login user.  ログイン管理者。 
         * @param {Login} [login] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(login?: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Login admin.  ログイン管理者。 
         * @param {Login} [login] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAdmin(login?: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAdmin(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthAPIApi - factory interface
 * @export
 */
export const AuthAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthAPIApiFp(configuration)
    return {
        /**
         * Login user.  ログイン管理者。 
         * @param {Login} [login] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login?: Login, options?: any): AxiosPromise<Token> {
            return localVarFp.login(login, options).then((request) => request(axios, basePath));
        },
        /**
         * Login admin.  ログイン管理者。 
         * @param {Login} [login] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAdmin(login?: Login, options?: any): AxiosPromise<Token> {
            return localVarFp.loginAdmin(login, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthAPIApi - object-oriented interface
 * @export
 * @class AuthAPIApi
 * @extends {BaseAPI}
 */
export class AuthAPIApi extends BaseAPI {
    /**
     * Login user.  ログイン管理者。 
     * @param {Login} [login] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPIApi
     */
    public login(login?: Login, options?: AxiosRequestConfig) {
        return AuthAPIApiFp(this.configuration).login(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Login admin.  ログイン管理者。 
     * @param {Login} [login] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPIApi
     */
    public loginAdmin(login?: Login, options?: AxiosRequestConfig) {
        return AuthAPIApiFp(this.configuration).loginAdmin(login, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmissionFactorAPIApi - axios parameter creator
 * @export
 */
export const EmissionFactorAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new emission factor.  新しい排出係数を作成します。 
         * @param {EmissionFactorCreate} [emissionFactorCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmissionFactor: async (emissionFactorCreate?: EmissionFactorCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/emissionFactors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emissionFactorCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new emission factor table.  新しい排出係数表を作成します。 
         * @param {EmissionFactorTableCreate} [emissionFactorTableCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmissionFactorTable: async (emissionFactorTableCreate?: EmissionFactorTableCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/emissionFactorTables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emissionFactorTableCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get emission factor  排出係数を取得します。 
         * @param {number} emissionFactorId Emission Factor ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmissionFactor: async (emissionFactorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emissionFactorId' is not null or undefined
            assertParamExists('getEmissionFactor', 'emissionFactorId', emissionFactorId)
            const localVarPath = `/emissionFactors/{emissionFactorId}`
                .replace(`{${"emissionFactorId"}}`, encodeURIComponent(String(emissionFactorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get emission factor table 
         * @param {number} emissionFactorTableId Emission Factor Table ID
         * @param {number} [year] Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmissionFactorTable: async (emissionFactorTableId: number, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emissionFactorTableId' is not null or undefined
            assertParamExists('getEmissionFactorTable', 'emissionFactorTableId', emissionFactorTableId)
            const localVarPath = `/emissionFactorTables/{emissionFactorTableId}`
                .replace(`{${"emissionFactorTableId"}}`, encodeURIComponent(String(emissionFactorTableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get emission factor tables 
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmissionFactorTables: async (search?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/emissionFactorTables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get emission factor 
         * @param {string} [search] Search
         * @param {number} [emissionFactorTableId] Emission Factor Table ID
         * @param {number} [year] Year
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmissionFactors: async (search?: string, emissionFactorTableId?: number, year?: number, offset?: number, limit?: number, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/emissionFactors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (emissionFactorTableId !== undefined) {
                localVarQueryParameter['emissionFactorTableId'] = emissionFactorTableId;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a emission factor.  排出係数を更新します。 
         * @param {number} emissionFactorId Emission Factor ID
         * @param {EmissionFactorUpdate} [emissionFactorUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmissionFactor: async (emissionFactorId: number, emissionFactorUpdate?: EmissionFactorUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emissionFactorId' is not null or undefined
            assertParamExists('updateEmissionFactor', 'emissionFactorId', emissionFactorId)
            const localVarPath = `/emissionFactors/{emissionFactorId}`
                .replace(`{${"emissionFactorId"}}`, encodeURIComponent(String(emissionFactorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emissionFactorUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a emission factor table.  排出係数表を更新します。 
         * @param {number} emissionFactorTableId Emission Factor Table ID
         * @param {EmissionFactorTableUpdate} [emissionFactorTableUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmissionFactorTable: async (emissionFactorTableId: number, emissionFactorTableUpdate?: EmissionFactorTableUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emissionFactorTableId' is not null or undefined
            assertParamExists('updateEmissionFactorTable', 'emissionFactorTableId', emissionFactorTableId)
            const localVarPath = `/emissionFactorTables/{emissionFactorTableId}`
                .replace(`{${"emissionFactorTableId"}}`, encodeURIComponent(String(emissionFactorTableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emissionFactorTableUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmissionFactorAPIApi - functional programming interface
 * @export
 */
export const EmissionFactorAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmissionFactorAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new emission factor.  新しい排出係数を作成します。 
         * @param {EmissionFactorCreate} [emissionFactorCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmissionFactor(emissionFactorCreate?: EmissionFactorCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmissionFactor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmissionFactor(emissionFactorCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new emission factor table.  新しい排出係数表を作成します。 
         * @param {EmissionFactorTableCreate} [emissionFactorTableCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmissionFactorTable(emissionFactorTableCreate?: EmissionFactorTableCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmissionFactorTable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmissionFactorTable(emissionFactorTableCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get emission factor  排出係数を取得します。 
         * @param {number} emissionFactorId Emission Factor ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmissionFactor(emissionFactorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmissionFactor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmissionFactor(emissionFactorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get emission factor table 
         * @param {number} emissionFactorTableId Emission Factor Table ID
         * @param {number} [year] Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmissionFactorTable(emissionFactorTableId: number, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmissionFactorTable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmissionFactorTable(emissionFactorTableId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get emission factor tables 
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmissionFactorTables(search?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmissionFactorTableList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmissionFactorTables(search, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get emission factor 
         * @param {string} [search] Search
         * @param {number} [emissionFactorTableId] Emission Factor Table ID
         * @param {number} [year] Year
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmissionFactors(search?: string, emissionFactorTableId?: number, year?: number, offset?: number, limit?: number, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmissionFactorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmissionFactors(search, emissionFactorTableId, year, offset, limit, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a emission factor.  排出係数を更新します。 
         * @param {number} emissionFactorId Emission Factor ID
         * @param {EmissionFactorUpdate} [emissionFactorUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmissionFactor(emissionFactorId: number, emissionFactorUpdate?: EmissionFactorUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmissionFactor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmissionFactor(emissionFactorId, emissionFactorUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a emission factor table.  排出係数表を更新します。 
         * @param {number} emissionFactorTableId Emission Factor Table ID
         * @param {EmissionFactorTableUpdate} [emissionFactorTableUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmissionFactorTable(emissionFactorTableId: number, emissionFactorTableUpdate?: EmissionFactorTableUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmissionFactorTable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmissionFactorTable(emissionFactorTableId, emissionFactorTableUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmissionFactorAPIApi - factory interface
 * @export
 */
export const EmissionFactorAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmissionFactorAPIApiFp(configuration)
    return {
        /**
         * Creates a new emission factor.  新しい排出係数を作成します。 
         * @param {EmissionFactorCreate} [emissionFactorCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmissionFactor(emissionFactorCreate?: EmissionFactorCreate, options?: any): AxiosPromise<EmissionFactor> {
            return localVarFp.createEmissionFactor(emissionFactorCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new emission factor table.  新しい排出係数表を作成します。 
         * @param {EmissionFactorTableCreate} [emissionFactorTableCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmissionFactorTable(emissionFactorTableCreate?: EmissionFactorTableCreate, options?: any): AxiosPromise<EmissionFactorTable> {
            return localVarFp.createEmissionFactorTable(emissionFactorTableCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * get emission factor  排出係数を取得します。 
         * @param {number} emissionFactorId Emission Factor ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmissionFactor(emissionFactorId: number, options?: any): AxiosPromise<EmissionFactor> {
            return localVarFp.getEmissionFactor(emissionFactorId, options).then((request) => request(axios, basePath));
        },
        /**
         * get emission factor table 
         * @param {number} emissionFactorTableId Emission Factor Table ID
         * @param {number} [year] Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmissionFactorTable(emissionFactorTableId: number, year?: number, options?: any): AxiosPromise<EmissionFactorTable> {
            return localVarFp.getEmissionFactorTable(emissionFactorTableId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * get emission factor tables 
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmissionFactorTables(search?: string, offset?: number, limit?: number, options?: any): AxiosPromise<EmissionFactorTableList> {
            return localVarFp.getEmissionFactorTables(search, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get emission factor 
         * @param {string} [search] Search
         * @param {number} [emissionFactorTableId] Emission Factor Table ID
         * @param {number} [year] Year
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmissionFactors(search?: string, emissionFactorTableId?: number, year?: number, offset?: number, limit?: number, type?: string, options?: any): AxiosPromise<EmissionFactorList> {
            return localVarFp.getEmissionFactors(search, emissionFactorTableId, year, offset, limit, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a emission factor.  排出係数を更新します。 
         * @param {number} emissionFactorId Emission Factor ID
         * @param {EmissionFactorUpdate} [emissionFactorUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmissionFactor(emissionFactorId: number, emissionFactorUpdate?: EmissionFactorUpdate, options?: any): AxiosPromise<EmissionFactor> {
            return localVarFp.updateEmissionFactor(emissionFactorId, emissionFactorUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a emission factor table.  排出係数表を更新します。 
         * @param {number} emissionFactorTableId Emission Factor Table ID
         * @param {EmissionFactorTableUpdate} [emissionFactorTableUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmissionFactorTable(emissionFactorTableId: number, emissionFactorTableUpdate?: EmissionFactorTableUpdate, options?: any): AxiosPromise<EmissionFactorTable> {
            return localVarFp.updateEmissionFactorTable(emissionFactorTableId, emissionFactorTableUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmissionFactorAPIApi - object-oriented interface
 * @export
 * @class EmissionFactorAPIApi
 * @extends {BaseAPI}
 */
export class EmissionFactorAPIApi extends BaseAPI {
    /**
     * Creates a new emission factor.  新しい排出係数を作成します。 
     * @param {EmissionFactorCreate} [emissionFactorCreate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmissionFactorAPIApi
     */
    public createEmissionFactor(emissionFactorCreate?: EmissionFactorCreate, options?: AxiosRequestConfig) {
        return EmissionFactorAPIApiFp(this.configuration).createEmissionFactor(emissionFactorCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new emission factor table.  新しい排出係数表を作成します。 
     * @param {EmissionFactorTableCreate} [emissionFactorTableCreate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmissionFactorAPIApi
     */
    public createEmissionFactorTable(emissionFactorTableCreate?: EmissionFactorTableCreate, options?: AxiosRequestConfig) {
        return EmissionFactorAPIApiFp(this.configuration).createEmissionFactorTable(emissionFactorTableCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get emission factor  排出係数を取得します。 
     * @param {number} emissionFactorId Emission Factor ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmissionFactorAPIApi
     */
    public getEmissionFactor(emissionFactorId: number, options?: AxiosRequestConfig) {
        return EmissionFactorAPIApiFp(this.configuration).getEmissionFactor(emissionFactorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get emission factor table 
     * @param {number} emissionFactorTableId Emission Factor Table ID
     * @param {number} [year] Year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmissionFactorAPIApi
     */
    public getEmissionFactorTable(emissionFactorTableId: number, year?: number, options?: AxiosRequestConfig) {
        return EmissionFactorAPIApiFp(this.configuration).getEmissionFactorTable(emissionFactorTableId, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get emission factor tables 
     * @param {string} [search] Search
     * @param {number} [offset] offset on paging
     * @param {number} [limit] number record of page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmissionFactorAPIApi
     */
    public getEmissionFactorTables(search?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return EmissionFactorAPIApiFp(this.configuration).getEmissionFactorTables(search, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get emission factor 
     * @param {string} [search] Search
     * @param {number} [emissionFactorTableId] Emission Factor Table ID
     * @param {number} [year] Year
     * @param {number} [offset] offset on paging
     * @param {number} [limit] number record of page
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmissionFactorAPIApi
     */
    public getEmissionFactors(search?: string, emissionFactorTableId?: number, year?: number, offset?: number, limit?: number, type?: string, options?: AxiosRequestConfig) {
        return EmissionFactorAPIApiFp(this.configuration).getEmissionFactors(search, emissionFactorTableId, year, offset, limit, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a emission factor.  排出係数を更新します。 
     * @param {number} emissionFactorId Emission Factor ID
     * @param {EmissionFactorUpdate} [emissionFactorUpdate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmissionFactorAPIApi
     */
    public updateEmissionFactor(emissionFactorId: number, emissionFactorUpdate?: EmissionFactorUpdate, options?: AxiosRequestConfig) {
        return EmissionFactorAPIApiFp(this.configuration).updateEmissionFactor(emissionFactorId, emissionFactorUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a emission factor table.  排出係数表を更新します。 
     * @param {number} emissionFactorTableId Emission Factor Table ID
     * @param {EmissionFactorTableUpdate} [emissionFactorTableUpdate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmissionFactorAPIApi
     */
    public updateEmissionFactorTable(emissionFactorTableId: number, emissionFactorTableUpdate?: EmissionFactorTableUpdate, options?: AxiosRequestConfig) {
        return EmissionFactorAPIApiFp(this.configuration).updateEmissionFactorTable(emissionFactorTableId, emissionFactorTableUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnergySavingApi - axios parameter creator
 * @export
 */
export const EnergySavingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get list energy saving types 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergySavingTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/energySaving`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnergySavingApi - functional programming interface
 * @export
 */
export const EnergySavingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnergySavingApiAxiosParamCreator(configuration)
    return {
        /**
         * get list energy saving types 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergySavingTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnergySaving>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnergySavingTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnergySavingApi - factory interface
 * @export
 */
export const EnergySavingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnergySavingApiFp(configuration)
    return {
        /**
         * get list energy saving types 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergySavingTypes(options?: any): AxiosPromise<EnergySaving> {
            return localVarFp.getEnergySavingTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnergySavingApi - object-oriented interface
 * @export
 * @class EnergySavingApi
 * @extends {BaseAPI}
 */
export class EnergySavingApi extends BaseAPI {
    /**
     * get list energy saving types 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergySavingApi
     */
    public getEnergySavingTypes(options?: AxiosRequestConfig) {
        return EnergySavingApiFp(this.configuration).getEnergySavingTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnergySavingLevelApi - axios parameter creator
 * @export
 */
export const EnergySavingLevelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get list energy saving level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergySavingLevel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/energySavingLevel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnergySavingLevelApi - functional programming interface
 * @export
 */
export const EnergySavingLevelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnergySavingLevelApiAxiosParamCreator(configuration)
    return {
        /**
         * get list energy saving level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergySavingLevel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnergySavingLevel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnergySavingLevel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnergySavingLevelApi - factory interface
 * @export
 */
export const EnergySavingLevelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnergySavingLevelApiFp(configuration)
    return {
        /**
         * get list energy saving level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergySavingLevel(options?: any): AxiosPromise<Array<EnergySavingLevel>> {
            return localVarFp.getEnergySavingLevel(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnergySavingLevelApi - object-oriented interface
 * @export
 * @class EnergySavingLevelApi
 * @extends {BaseAPI}
 */
export class EnergySavingLevelApi extends BaseAPI {
    /**
     * get list energy saving level 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergySavingLevelApi
     */
    public getEnergySavingLevel(options?: AxiosRequestConfig) {
        return EnergySavingLevelApiFp(this.configuration).getEnergySavingLevel(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnergySavingReportApi - axios parameter creator
 * @export
 */
export const EnergySavingReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create/Update report energy_saving_originate by site and year. 
         * @param {EnergySavingOriginateCreate} [energySavingOriginateCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnergySavingOriginate: async (energySavingOriginateCreate?: EnergySavingOriginateCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/energySavingReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(energySavingOriginateCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get report energy saving by site 
         * @param {string} [search] Search
         * @param {number} [year] Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergySavingReport: async (search?: string, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/energySavingReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnergySavingReportApi - functional programming interface
 * @export
 */
export const EnergySavingReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnergySavingReportApiAxiosParamCreator(configuration)
    return {
        /**
         * Create/Update report energy_saving_originate by site and year. 
         * @param {EnergySavingOriginateCreate} [energySavingOriginateCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEnergySavingOriginate(energySavingOriginateCreate?: EnergySavingOriginateCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnergySavingOriginate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEnergySavingOriginate(energySavingOriginateCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get report energy saving by site 
         * @param {string} [search] Search
         * @param {number} [year] Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergySavingReport(search?: string, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnergySavingReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnergySavingReport(search, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnergySavingReportApi - factory interface
 * @export
 */
export const EnergySavingReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnergySavingReportApiFp(configuration)
    return {
        /**
         * Create/Update report energy_saving_originate by site and year. 
         * @param {EnergySavingOriginateCreate} [energySavingOriginateCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnergySavingOriginate(energySavingOriginateCreate?: EnergySavingOriginateCreate, options?: any): AxiosPromise<Array<EnergySavingOriginate>> {
            return localVarFp.createEnergySavingOriginate(energySavingOriginateCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * get report energy saving by site 
         * @param {string} [search] Search
         * @param {number} [year] Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergySavingReport(search?: string, year?: number, options?: any): AxiosPromise<Array<EnergySavingReport>> {
            return localVarFp.getEnergySavingReport(search, year, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnergySavingReportApi - object-oriented interface
 * @export
 * @class EnergySavingReportApi
 * @extends {BaseAPI}
 */
export class EnergySavingReportApi extends BaseAPI {
    /**
     * Create/Update report energy_saving_originate by site and year. 
     * @param {EnergySavingOriginateCreate} [energySavingOriginateCreate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergySavingReportApi
     */
    public createEnergySavingOriginate(energySavingOriginateCreate?: EnergySavingOriginateCreate, options?: AxiosRequestConfig) {
        return EnergySavingReportApiFp(this.configuration).createEnergySavingOriginate(energySavingOriginateCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get report energy saving by site 
     * @param {string} [search] Search
     * @param {number} [year] Year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergySavingReportApi
     */
    public getEnergySavingReport(search?: string, year?: number, options?: AxiosRequestConfig) {
        return EnergySavingReportApiFp(this.configuration).getEnergySavingReport(search, year, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdeaVersionAPIApi - axios parameter creator
 * @export
 */
export const IdeaVersionAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get idea versions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaVersions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/idea-versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdeaVersionAPIApi - functional programming interface
 * @export
 */
export const IdeaVersionAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdeaVersionAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * get idea versions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdeaVersions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdeaVersionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdeaVersions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdeaVersionAPIApi - factory interface
 * @export
 */
export const IdeaVersionAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdeaVersionAPIApiFp(configuration)
    return {
        /**
         * get idea versions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaVersions(options?: any): AxiosPromise<IdeaVersionList> {
            return localVarFp.getIdeaVersions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdeaVersionAPIApi - object-oriented interface
 * @export
 * @class IdeaVersionAPIApi
 * @extends {BaseAPI}
 */
export class IdeaVersionAPIApi extends BaseAPI {
    /**
     * get idea versions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaVersionAPIApi
     */
    public getIdeaVersions(options?: AxiosRequestConfig) {
        return IdeaVersionAPIApiFp(this.configuration).getIdeaVersions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationAPIApi - axios parameter creator
 * @export
 */
export const NotificationAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new notification.  新しい通知を作成します。 
         * @param {NotificationCreate} [notificationCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification: async (notificationCreate?: NotificationCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the specified notification.  指定された通知を削除します。 
         * @param {number} notificationId Notification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotification: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('deleteNotification', 'notificationId', notificationId)
            const localVarPath = `/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get new notifications number.  リスト通知を取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a notification.  通知を受け取ります。 
         * @param {number} notificationId Notification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('getNotification', 'notificationId', notificationId)
            const localVarPath = `/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list notifications.  リスト通知を取得します。 
         * @param {string} [search] Search
         * @param {number} [year] Year
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications: async (search?: string, year?: number, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a notification.  通知を更新します。 
         * @param {number} notificationId Notification ID
         * @param {NotificationUpdate} [notificationUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification: async (notificationId: number, notificationUpdate?: NotificationUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('updateNotification', 'notificationId', notificationId)
            const localVarPath = `/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update notifications status.  通知を更新します。 
         * @param {Array<NotificationStatusUpdate>} [notificationStatusUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationStatus: async (notificationStatusUpdate?: Array<NotificationStatusUpdate>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationStatusUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationAPIApi - functional programming interface
 * @export
 */
export const NotificationAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new notification.  新しい通知を作成します。 
         * @param {NotificationCreate} [notificationCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotification(notificationCreate?: NotificationCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotification(notificationCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the specified notification.  指定された通知を削除します。 
         * @param {number} notificationId Notification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotification(notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotification(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get new notifications number.  リスト通知を取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationNew>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a notification.  通知を受け取ります。 
         * @param {number} notificationId Notification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotification(notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotification(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list notifications.  リスト通知を取得します。 
         * @param {string} [search] Search
         * @param {number} [year] Year
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotifications(search?: string, year?: number, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotifications(search, year, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a notification.  通知を更新します。 
         * @param {number} notificationId Notification ID
         * @param {NotificationUpdate} [notificationUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotification(notificationId: number, notificationUpdate?: NotificationUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotification(notificationId, notificationUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update notifications status.  通知を更新します。 
         * @param {Array<NotificationStatusUpdate>} [notificationStatusUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationStatus(notificationStatusUpdate?: Array<NotificationStatusUpdate>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationStatus(notificationStatusUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationAPIApi - factory interface
 * @export
 */
export const NotificationAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationAPIApiFp(configuration)
    return {
        /**
         * Creates a new notification.  新しい通知を作成します。 
         * @param {NotificationCreate} [notificationCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification(notificationCreate?: NotificationCreate, options?: any): AxiosPromise<Notification> {
            return localVarFp.createNotification(notificationCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the specified notification.  指定された通知を削除します。 
         * @param {number} notificationId Notification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotification(notificationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNotification(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get new notifications number.  リスト通知を取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewNotifications(options?: any): AxiosPromise<NotificationNew> {
            return localVarFp.getNewNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a notification.  通知を受け取ります。 
         * @param {number} notificationId Notification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification(notificationId: number, options?: any): AxiosPromise<Notification> {
            return localVarFp.getNotification(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list notifications.  リスト通知を取得します。 
         * @param {string} [search] Search
         * @param {number} [year] Year
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications(search?: string, year?: number, offset?: number, limit?: number, options?: any): AxiosPromise<NotificationList> {
            return localVarFp.listNotifications(search, year, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a notification.  通知を更新します。 
         * @param {number} notificationId Notification ID
         * @param {NotificationUpdate} [notificationUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification(notificationId: number, notificationUpdate?: NotificationUpdate, options?: any): AxiosPromise<Notification> {
            return localVarFp.updateNotification(notificationId, notificationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update notifications status.  通知を更新します。 
         * @param {Array<NotificationStatusUpdate>} [notificationStatusUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationStatus(notificationStatusUpdate?: Array<NotificationStatusUpdate>, options?: any): AxiosPromise<void> {
            return localVarFp.updateNotificationStatus(notificationStatusUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationAPIApi - object-oriented interface
 * @export
 * @class NotificationAPIApi
 * @extends {BaseAPI}
 */
export class NotificationAPIApi extends BaseAPI {
    /**
     * Creates a new notification.  新しい通知を作成します。 
     * @param {NotificationCreate} [notificationCreate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public createNotification(notificationCreate?: NotificationCreate, options?: AxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).createNotification(notificationCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the specified notification.  指定された通知を削除します。 
     * @param {number} notificationId Notification ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public deleteNotification(notificationId: number, options?: AxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).deleteNotification(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get new notifications number.  リスト通知を取得します。 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public getNewNotifications(options?: AxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).getNewNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a notification.  通知を受け取ります。 
     * @param {number} notificationId Notification ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public getNotification(notificationId: number, options?: AxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).getNotification(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list notifications.  リスト通知を取得します。 
     * @param {string} [search] Search
     * @param {number} [year] Year
     * @param {number} [offset] offset on paging
     * @param {number} [limit] number record of page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public listNotifications(search?: string, year?: number, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).listNotifications(search, year, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a notification.  通知を更新します。 
     * @param {number} notificationId Notification ID
     * @param {NotificationUpdate} [notificationUpdate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public updateNotification(notificationId: number, notificationUpdate?: NotificationUpdate, options?: AxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).updateNotification(notificationId, notificationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update notifications status.  通知を更新します。 
     * @param {Array<NotificationStatusUpdate>} [notificationStatusUpdate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public updateNotificationStatus(notificationStatusUpdate?: Array<NotificationStatusUpdate>, options?: AxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).updateNotificationStatus(notificationStatusUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationAPIApi - axios parameter creator
 * @export
 */
export const OrganizationAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new organization.  新規に組織を作成します。 
         * @param {OrganizationCreate} [organizationCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization: async (organizationCreate?: OrganizationCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new site.  新規に拠点を作成します。 
         * @param {number} organizationId Organization ID
         * @param {SiteCreate} [siteCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationSite: async (organizationId: number, siteCreate?: SiteCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createOrganizationSite', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/sites`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(siteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new user.  新規にユーザーを作成します。 
         * @param {number} organizationId Organization ID
         * @param {UserCreate} [userCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationUser: async (organizationId: number, userCreate?: UserCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createOrganizationUser', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/users`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the specified organization.  指定の組織を削除します。 
         * @param {number} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the specified organization.  指定の組織を取得します。 
         * @param {number} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets objectives of the specified organization.  指定の組織の年度ごとの目標リストを取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationObjectives: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/objectives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list site.  リスト サイトを取得します。 
         * @param {number} organizationId Organization ID
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationSites: async (organizationId: number, search?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationSites', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/sites`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets objectives of all sites of the specified organization.  指定の組織の全拠点の年度ごとの目標リストを取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationSitesObjectives: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/sites/objectives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets users who belong to the specified organization.  指定の組織に所属するユーザーを取得します。 
         * @param {number} organizationId Organization ID
         * @param {string} [search] Search
         * @param {number} [role] Role
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers: async (organizationId: number, search?: string, role?: number, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationUsers', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/users`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets objectives of the specified site.  指定の拠点の年度ごとの目標リストを取得します。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteObjectives: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteObjectives', 'siteId', siteId)
            const localVarPath = `/sites/{siteId}/objectives`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list organization.  リストの編成を取得します。 
         * @param {string} [search] Search
         * @param {number} [planId] plan id of organization
         * @param {number} [status] Status
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganization: async (search?: string, planId?: number, status?: number, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates or updates an objective of the specified organization of the year.  指定の組織の年度の目標を追加・更新します。 
         * @param {Objective} [objective] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOrganizationObjective: async (objective?: Objective, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/objectives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objective, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates or updates an objective of the specified site of the year.  指定の拠点の年度の目標を追加・更新します。 
         * @param {number} siteId Site ID
         * @param {Objective} [objective] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteObjective: async (siteId: number, objective?: Objective, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('saveSiteObjective', 'siteId', siteId)
            const localVarPath = `/sites/{siteId}/objectives`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objective, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the specified organization.  指定の組織を更新します。 
         * @param {number} organizationId Organization ID
         * @param {OrganizationUpdate} [organizationUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (organizationId: number, organizationUpdate?: OrganizationUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the specified organization name.  指定の組織を更新します。 
         * @param {OrganizationName} [organizationName] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationName: async (organizationName?: OrganizationName, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationName, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the user organization.  指定の組織を更新します。 
         * @param {OrganizationProfileUpdate} [organizationProfileUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationProfile: async (organizationProfileUpdate?: OrganizationProfileUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationProfileUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the specified organization start month.  指定の組織を更新します。 
         * @param {OrganizationStartMonth} [organizationStartMonth] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationStartMonth: async (organizationStartMonth?: OrganizationStartMonth, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/start_month`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationStartMonth, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationAPIApi - functional programming interface
 * @export
 */
export const OrganizationAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new organization.  新規に組織を作成します。 
         * @param {OrganizationCreate} [organizationCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganization(organizationCreate?: OrganizationCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganization(organizationCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new site.  新規に拠点を作成します。 
         * @param {number} organizationId Organization ID
         * @param {SiteCreate} [siteCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganizationSite(organizationId: number, siteCreate?: SiteCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganizationSite(organizationId, siteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new user.  新規にユーザーを作成します。 
         * @param {number} organizationId Organization ID
         * @param {UserCreate} [userCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganizationUser(organizationId: number, userCreate?: UserCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganizationUser(organizationId, userCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the specified organization.  指定の組織を削除します。 
         * @param {number} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganization(organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the specified organization.  指定の組織を取得します。 
         * @param {number} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganization(organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets objectives of the specified organization.  指定の組織の年度ごとの目標リストを取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationObjectives(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Objectives>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationObjectives(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the list site.  リスト サイトを取得します。 
         * @param {number} organizationId Organization ID
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationSites(organizationId: number, search?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationSites(organizationId, search, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets objectives of all sites of the specified organization.  指定の組織の全拠点の年度ごとの目標リストを取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationSitesObjectives(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteObjectives>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationSitesObjectives(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets users who belong to the specified organization.  指定の組織に所属するユーザーを取得します。 
         * @param {number} organizationId Organization ID
         * @param {string} [search] Search
         * @param {number} [role] Role
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUsers(organizationId: number, search?: string, role?: number, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationUsers(organizationId, search, role, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets objectives of the specified site.  指定の拠点の年度ごとの目標リストを取得します。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteObjectives(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Objectives>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteObjectives(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the list organization.  リストの編成を取得します。 
         * @param {string} [search] Search
         * @param {number} [planId] plan id of organization
         * @param {number} [status] Status
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganization(search?: string, planId?: number, status?: number, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrganization(search, planId, status, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates or updates an objective of the specified organization of the year.  指定の組織の年度の目標を追加・更新します。 
         * @param {Objective} [objective] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveOrganizationObjective(objective?: Objective, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Objective>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveOrganizationObjective(objective, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates or updates an objective of the specified site of the year.  指定の拠点の年度の目標を追加・更新します。 
         * @param {number} siteId Site ID
         * @param {Objective} [objective] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSiteObjective(siteId: number, objective?: Objective, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Objective>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSiteObjective(siteId, objective, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the specified organization.  指定の組織を更新します。 
         * @param {number} organizationId Organization ID
         * @param {OrganizationUpdate} [organizationUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(organizationId: number, organizationUpdate?: OrganizationUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(organizationId, organizationUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the specified organization name.  指定の組織を更新します。 
         * @param {OrganizationName} [organizationName] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationName(organizationName?: OrganizationName, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationName(organizationName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the user organization.  指定の組織を更新します。 
         * @param {OrganizationProfileUpdate} [organizationProfileUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationProfile(organizationProfileUpdate?: OrganizationProfileUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationProfile(organizationProfileUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the specified organization start month.  指定の組織を更新します。 
         * @param {OrganizationStartMonth} [organizationStartMonth] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationStartMonth(organizationStartMonth?: OrganizationStartMonth, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationStartMonth(organizationStartMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationAPIApi - factory interface
 * @export
 */
export const OrganizationAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationAPIApiFp(configuration)
    return {
        /**
         * Creates a new organization.  新規に組織を作成します。 
         * @param {OrganizationCreate} [organizationCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(organizationCreate?: OrganizationCreate, options?: any): AxiosPromise<Organization> {
            return localVarFp.createOrganization(organizationCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new site.  新規に拠点を作成します。 
         * @param {number} organizationId Organization ID
         * @param {SiteCreate} [siteCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationSite(organizationId: number, siteCreate?: SiteCreate, options?: any): AxiosPromise<Site> {
            return localVarFp.createOrganizationSite(organizationId, siteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new user.  新規にユーザーを作成します。 
         * @param {number} organizationId Organization ID
         * @param {UserCreate} [userCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationUser(organizationId: number, userCreate?: UserCreate, options?: any): AxiosPromise<User> {
            return localVarFp.createOrganizationUser(organizationId, userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the specified organization.  指定の組織を削除します。 
         * @param {number} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization(organizationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the specified organization.  指定の組織を取得します。 
         * @param {number} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(organizationId: number, options?: any): AxiosPromise<Organization> {
            return localVarFp.getOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets objectives of the specified organization.  指定の組織の年度ごとの目標リストを取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationObjectives(options?: any): AxiosPromise<Objectives> {
            return localVarFp.getOrganizationObjectives(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list site.  リスト サイトを取得します。 
         * @param {number} organizationId Organization ID
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationSites(organizationId: number, search?: string, offset?: number, limit?: number, options?: any): AxiosPromise<SiteList> {
            return localVarFp.getOrganizationSites(organizationId, search, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets objectives of all sites of the specified organization.  指定の組織の全拠点の年度ごとの目標リストを取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationSitesObjectives(options?: any): AxiosPromise<SiteObjectives> {
            return localVarFp.getOrganizationSitesObjectives(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets users who belong to the specified organization.  指定の組織に所属するユーザーを取得します。 
         * @param {number} organizationId Organization ID
         * @param {string} [search] Search
         * @param {number} [role] Role
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers(organizationId: number, search?: string, role?: number, offset?: number, limit?: number, options?: any): AxiosPromise<UserList> {
            return localVarFp.getOrganizationUsers(organizationId, search, role, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets objectives of the specified site.  指定の拠点の年度ごとの目標リストを取得します。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteObjectives(siteId: number, options?: any): AxiosPromise<Objectives> {
            return localVarFp.getSiteObjectives(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list organization.  リストの編成を取得します。 
         * @param {string} [search] Search
         * @param {number} [planId] plan id of organization
         * @param {number} [status] Status
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganization(search?: string, planId?: number, status?: number, offset?: number, limit?: number, options?: any): AxiosPromise<OrganizationList> {
            return localVarFp.listOrganization(search, planId, status, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates or updates an objective of the specified organization of the year.  指定の組織の年度の目標を追加・更新します。 
         * @param {Objective} [objective] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOrganizationObjective(objective?: Objective, options?: any): AxiosPromise<Objective> {
            return localVarFp.saveOrganizationObjective(objective, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates or updates an objective of the specified site of the year.  指定の拠点の年度の目標を追加・更新します。 
         * @param {number} siteId Site ID
         * @param {Objective} [objective] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteObjective(siteId: number, objective?: Objective, options?: any): AxiosPromise<Objective> {
            return localVarFp.saveSiteObjective(siteId, objective, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the specified organization.  指定の組織を更新します。 
         * @param {number} organizationId Organization ID
         * @param {OrganizationUpdate} [organizationUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(organizationId: number, organizationUpdate?: OrganizationUpdate, options?: any): AxiosPromise<Organization> {
            return localVarFp.updateOrganization(organizationId, organizationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the specified organization name.  指定の組織を更新します。 
         * @param {OrganizationName} [organizationName] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationName(organizationName?: OrganizationName, options?: any): AxiosPromise<Organization> {
            return localVarFp.updateOrganizationName(organizationName, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the user organization.  指定の組織を更新します。 
         * @param {OrganizationProfileUpdate} [organizationProfileUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationProfile(organizationProfileUpdate?: OrganizationProfileUpdate, options?: any): AxiosPromise<Organization> {
            return localVarFp.updateOrganizationProfile(organizationProfileUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the specified organization start month.  指定の組織を更新します。 
         * @param {OrganizationStartMonth} [organizationStartMonth] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationStartMonth(organizationStartMonth?: OrganizationStartMonth, options?: any): AxiosPromise<Organization> {
            return localVarFp.updateOrganizationStartMonth(organizationStartMonth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationAPIApi - object-oriented interface
 * @export
 * @class OrganizationAPIApi
 * @extends {BaseAPI}
 */
export class OrganizationAPIApi extends BaseAPI {
    /**
     * Creates a new organization.  新規に組織を作成します。 
     * @param {OrganizationCreate} [organizationCreate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public createOrganization(organizationCreate?: OrganizationCreate, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).createOrganization(organizationCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new site.  新規に拠点を作成します。 
     * @param {number} organizationId Organization ID
     * @param {SiteCreate} [siteCreate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public createOrganizationSite(organizationId: number, siteCreate?: SiteCreate, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).createOrganizationSite(organizationId, siteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new user.  新規にユーザーを作成します。 
     * @param {number} organizationId Organization ID
     * @param {UserCreate} [userCreate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public createOrganizationUser(organizationId: number, userCreate?: UserCreate, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).createOrganizationUser(organizationId, userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the specified organization.  指定の組織を削除します。 
     * @param {number} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public deleteOrganization(organizationId: number, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).deleteOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the specified organization.  指定の組織を取得します。 
     * @param {number} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public getOrganization(organizationId: number, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).getOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets objectives of the specified organization.  指定の組織の年度ごとの目標リストを取得します。 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public getOrganizationObjectives(options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).getOrganizationObjectives(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list site.  リスト サイトを取得します。 
     * @param {number} organizationId Organization ID
     * @param {string} [search] Search
     * @param {number} [offset] offset on paging
     * @param {number} [limit] number record of page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public getOrganizationSites(organizationId: number, search?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).getOrganizationSites(organizationId, search, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets objectives of all sites of the specified organization.  指定の組織の全拠点の年度ごとの目標リストを取得します。 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public getOrganizationSitesObjectives(options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).getOrganizationSitesObjectives(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets users who belong to the specified organization.  指定の組織に所属するユーザーを取得します。 
     * @param {number} organizationId Organization ID
     * @param {string} [search] Search
     * @param {number} [role] Role
     * @param {number} [offset] offset on paging
     * @param {number} [limit] number record of page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public getOrganizationUsers(organizationId: number, search?: string, role?: number, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).getOrganizationUsers(organizationId, search, role, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets objectives of the specified site.  指定の拠点の年度ごとの目標リストを取得します。 
     * @param {number} siteId Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public getSiteObjectives(siteId: number, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).getSiteObjectives(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list organization.  リストの編成を取得します。 
     * @param {string} [search] Search
     * @param {number} [planId] plan id of organization
     * @param {number} [status] Status
     * @param {number} [offset] offset on paging
     * @param {number} [limit] number record of page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public listOrganization(search?: string, planId?: number, status?: number, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).listOrganization(search, planId, status, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates or updates an objective of the specified organization of the year.  指定の組織の年度の目標を追加・更新します。 
     * @param {Objective} [objective] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public saveOrganizationObjective(objective?: Objective, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).saveOrganizationObjective(objective, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates or updates an objective of the specified site of the year.  指定の拠点の年度の目標を追加・更新します。 
     * @param {number} siteId Site ID
     * @param {Objective} [objective] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public saveSiteObjective(siteId: number, objective?: Objective, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).saveSiteObjective(siteId, objective, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the specified organization.  指定の組織を更新します。 
     * @param {number} organizationId Organization ID
     * @param {OrganizationUpdate} [organizationUpdate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public updateOrganization(organizationId: number, organizationUpdate?: OrganizationUpdate, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).updateOrganization(organizationId, organizationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the specified organization name.  指定の組織を更新します。 
     * @param {OrganizationName} [organizationName] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public updateOrganizationName(organizationName?: OrganizationName, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).updateOrganizationName(organizationName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the user organization.  指定の組織を更新します。 
     * @param {OrganizationProfileUpdate} [organizationProfileUpdate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public updateOrganizationProfile(organizationProfileUpdate?: OrganizationProfileUpdate, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).updateOrganizationProfile(organizationProfileUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the specified organization start month.  指定の組織を更新します。 
     * @param {OrganizationStartMonth} [organizationStartMonth] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAPIApi
     */
    public updateOrganizationStartMonth(organizationStartMonth?: OrganizationStartMonth, options?: AxiosRequestConfig) {
        return OrganizationAPIApiFp(this.configuration).updateOrganizationStartMonth(organizationStartMonth, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationIdeaVersionAPIApi - axios parameter creator
 * @export
 */
export const OrganizationIdeaVersionAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete the organization idea version. 
         * @param {number} organizationIdeaVersionId Organization Idea Version Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationIdeaVersion: async (organizationIdeaVersionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationIdeaVersionId' is not null or undefined
            assertParamExists('deleteOrganizationIdeaVersion', 'organizationIdeaVersionId', organizationIdeaVersionId)
            const localVarPath = `/organizations/idea-versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationIdeaVersionId !== undefined) {
                localVarQueryParameter['organizationIdeaVersionId'] = organizationIdeaVersionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get organization idea versions information 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationIdeaVersions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/idea-versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or update the organization idea version. 
         * @param {number} ideaVersionId Idea Version Id
         * @param {string} [fileName] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOrganizationIdeaVersion: async (ideaVersionId: number, fileName?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ideaVersionId' is not null or undefined
            assertParamExists('saveOrganizationIdeaVersion', 'ideaVersionId', ideaVersionId)
            const localVarPath = `/organizations/idea-versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ideaVersionId !== undefined) {
                localVarQueryParameter['ideaVersionId'] = ideaVersionId;
            }


            if (fileName !== undefined) { 
                localVarFormParams.append('fileName', fileName as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationIdeaVersionAPIApi - functional programming interface
 * @export
 */
export const OrganizationIdeaVersionAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationIdeaVersionAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete the organization idea version. 
         * @param {number} organizationIdeaVersionId Organization Idea Version Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganizationIdeaVersion(organizationIdeaVersionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganizationIdeaVersion(organizationIdeaVersionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get organization idea versions information 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationIdeaVersions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationIdeaVersionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationIdeaVersions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or update the organization idea version. 
         * @param {number} ideaVersionId Idea Version Id
         * @param {string} [fileName] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveOrganizationIdeaVersion(ideaVersionId: number, fileName?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationIdeaVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveOrganizationIdeaVersion(ideaVersionId, fileName, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationIdeaVersionAPIApi - factory interface
 * @export
 */
export const OrganizationIdeaVersionAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationIdeaVersionAPIApiFp(configuration)
    return {
        /**
         * Delete the organization idea version. 
         * @param {number} organizationIdeaVersionId Organization Idea Version Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationIdeaVersion(organizationIdeaVersionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrganizationIdeaVersion(organizationIdeaVersionId, options).then((request) => request(axios, basePath));
        },
        /**
         * get organization idea versions information 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationIdeaVersions(options?: any): AxiosPromise<OrganizationIdeaVersionList> {
            return localVarFp.getOrganizationIdeaVersions(options).then((request) => request(axios, basePath));
        },
        /**
         * Create or update the organization idea version. 
         * @param {number} ideaVersionId Idea Version Id
         * @param {string} [fileName] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOrganizationIdeaVersion(ideaVersionId: number, fileName?: string, file?: any, options?: any): AxiosPromise<OrganizationIdeaVersion> {
            return localVarFp.saveOrganizationIdeaVersion(ideaVersionId, fileName, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationIdeaVersionAPIApi - object-oriented interface
 * @export
 * @class OrganizationIdeaVersionAPIApi
 * @extends {BaseAPI}
 */
export class OrganizationIdeaVersionAPIApi extends BaseAPI {
    /**
     * Delete the organization idea version. 
     * @param {number} organizationIdeaVersionId Organization Idea Version Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationIdeaVersionAPIApi
     */
    public deleteOrganizationIdeaVersion(organizationIdeaVersionId: number, options?: AxiosRequestConfig) {
        return OrganizationIdeaVersionAPIApiFp(this.configuration).deleteOrganizationIdeaVersion(organizationIdeaVersionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get organization idea versions information 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationIdeaVersionAPIApi
     */
    public getOrganizationIdeaVersions(options?: AxiosRequestConfig) {
        return OrganizationIdeaVersionAPIApiFp(this.configuration).getOrganizationIdeaVersions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or update the organization idea version. 
     * @param {number} ideaVersionId Idea Version Id
     * @param {string} [fileName] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationIdeaVersionAPIApi
     */
    public saveOrganizationIdeaVersion(ideaVersionId: number, fileName?: string, file?: any, options?: AxiosRequestConfig) {
        return OrganizationIdeaVersionAPIApiFp(this.configuration).saveOrganizationIdeaVersion(ideaVersionId, fileName, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationSummaryAPIApi - axios parameter creator
 * @export
 */
export const OrganizationSummaryAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the year  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnualOrganizationSummary: async (year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getAnnualOrganizationSummary', 'year', year)
            const localVarPath = `/organizations/summary/years/{year}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the half a year  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
         * @param {number} year Year
         * @param {number} half Half
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHalfYearOrganizationSummary: async (year: number, half: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getHalfYearOrganizationSummary', 'year', year)
            // verify required parameter 'half' is not null or undefined
            assertParamExists('getHalfYearOrganizationSummary', 'half', half)
            const localVarPath = `/organizations/summary/years/{year}/half/{half}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"half"}}`, encodeURIComponent(String(half)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets CO2 emission summary of the organization. This includes: - Total emissions per month - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthOrganizationSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/summary/months`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets monthly CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per category  組織の月間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとのCO2総排出量 - 各スコープ・カテゴリごとのCO2総排出量 
         * @param {string} month Month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyOrganizationSummary: async (month: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getMonthlyOrganizationSummary', 'month', month)
            const localVarPath = `/organizations/summary/months/{month}`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the quarter  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
         * @param {number} year Year
         * @param {number} quarter Quarter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuarterlyOrganizationSummary: async (year: number, quarter: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getQuarterlyOrganizationSummary', 'year', year)
            // verify required parameter 'quarter' is not null or undefined
            assertParamExists('getQuarterlyOrganizationSummary', 'quarter', quarter)
            const localVarPath = `/organizations/summary/years/{year}/quarter/{quarter}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"quarter"}}`, encodeURIComponent(String(quarter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets CO2 emission summary of the organization. This includes: - Total emissions per year - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYearOrganizationSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/summary/years`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationSummaryAPIApi - functional programming interface
 * @export
 */
export const OrganizationSummaryAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationSummaryAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the year  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnualOrganizationSummary(year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnualOrganizationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnualOrganizationSummary(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the half a year  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
         * @param {number} year Year
         * @param {number} half Half
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHalfYearOrganizationSummary(year: number, half: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HalfYearOrganizationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHalfYearOrganizationSummary(year, half, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets CO2 emission summary of the organization. This includes: - Total emissions per month - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthOrganizationSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthOrganizationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthOrganizationSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets monthly CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per category  組織の月間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとのCO2総排出量 - 各スコープ・カテゴリごとのCO2総排出量 
         * @param {string} month Month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyOrganizationSummary(month: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlyOrganizationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyOrganizationSummary(month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the quarter  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
         * @param {number} year Year
         * @param {number} quarter Quarter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuarterlyOrganizationSummary(year: number, quarter: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuarterlyOrganizationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuarterlyOrganizationSummary(year, quarter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets CO2 emission summary of the organization. This includes: - Total emissions per year - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYearOrganizationSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YearOrganizationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYearOrganizationSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationSummaryAPIApi - factory interface
 * @export
 */
export const OrganizationSummaryAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationSummaryAPIApiFp(configuration)
    return {
        /**
         * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the year  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnualOrganizationSummary(year: number, options?: any): AxiosPromise<AnnualOrganizationSummary> {
            return localVarFp.getAnnualOrganizationSummary(year, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the half a year  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
         * @param {number} year Year
         * @param {number} half Half
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHalfYearOrganizationSummary(year: number, half: number, options?: any): AxiosPromise<HalfYearOrganizationSummary> {
            return localVarFp.getHalfYearOrganizationSummary(year, half, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets CO2 emission summary of the organization. This includes: - Total emissions per month - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthOrganizationSummary(options?: any): AxiosPromise<MonthOrganizationSummary> {
            return localVarFp.getMonthOrganizationSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets monthly CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per category  組織の月間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとのCO2総排出量 - 各スコープ・カテゴリごとのCO2総排出量 
         * @param {string} month Month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyOrganizationSummary(month: string, options?: any): AxiosPromise<MonthlyOrganizationSummary> {
            return localVarFp.getMonthlyOrganizationSummary(month, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the quarter  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
         * @param {number} year Year
         * @param {number} quarter Quarter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuarterlyOrganizationSummary(year: number, quarter: number, options?: any): AxiosPromise<QuarterlyOrganizationSummary> {
            return localVarFp.getQuarterlyOrganizationSummary(year, quarter, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets CO2 emission summary of the organization. This includes: - Total emissions per year - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYearOrganizationSummary(options?: any): AxiosPromise<YearOrganizationSummary> {
            return localVarFp.getYearOrganizationSummary(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationSummaryAPIApi - object-oriented interface
 * @export
 * @class OrganizationSummaryAPIApi
 * @extends {BaseAPI}
 */
export class OrganizationSummaryAPIApi extends BaseAPI {
    /**
     * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the year  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
     * @param {number} year Year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSummaryAPIApi
     */
    public getAnnualOrganizationSummary(year: number, options?: AxiosRequestConfig) {
        return OrganizationSummaryAPIApiFp(this.configuration).getAnnualOrganizationSummary(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the half a year  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
     * @param {number} year Year
     * @param {number} half Half
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSummaryAPIApi
     */
    public getHalfYearOrganizationSummary(year: number, half: number, options?: AxiosRequestConfig) {
        return OrganizationSummaryAPIApiFp(this.configuration).getHalfYearOrganizationSummary(year, half, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets CO2 emission summary of the organization. This includes: - Total emissions per month - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSummaryAPIApi
     */
    public getMonthOrganizationSummary(options?: AxiosRequestConfig) {
        return OrganizationSummaryAPIApiFp(this.configuration).getMonthOrganizationSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets monthly CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per category  組織の月間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとのCO2総排出量 - 各スコープ・カテゴリごとのCO2総排出量 
     * @param {string} month Month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSummaryAPIApi
     */
    public getMonthlyOrganizationSummary(month: string, options?: AxiosRequestConfig) {
        return OrganizationSummaryAPIApiFp(this.configuration).getMonthlyOrganizationSummary(month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets annual CO2 emission summary of the organization. This includes: - Total emissions per site - Total emissions per scope & category - Total emissions in each month in the quarter  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 各サイトごとの総排出量 - 各スコープ・カテゴリごとの総排出量 - 組織の各月における総排出量 
     * @param {number} year Year
     * @param {number} quarter Quarter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSummaryAPIApi
     */
    public getQuarterlyOrganizationSummary(year: number, quarter: number, options?: AxiosRequestConfig) {
        return OrganizationSummaryAPIApiFp(this.configuration).getQuarterlyOrganizationSummary(year, quarter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets CO2 emission summary of the organization. This includes: - Total emissions per year - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSummaryAPIApi
     */
    public getYearOrganizationSummary(options?: AxiosRequestConfig) {
        return OrganizationSummaryAPIApiFp(this.configuration).getYearOrganizationSummary(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportAPIApi - axios parameter creator
 * @export
 */
export const ReportAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Export Report. 
         * @param {ReportFilter} [reportFilter] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportReport: async (reportFilter?: ReportFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportAPIApi - functional programming interface
 * @export
 */
export const ReportAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Export Report. 
         * @param {ReportFilter} [reportFilter] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportReport(reportFilter?: ReportFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportReport(reportFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportAPIApi - factory interface
 * @export
 */
export const ReportAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportAPIApiFp(configuration)
    return {
        /**
         * Export Report. 
         * @param {ReportFilter} [reportFilter] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportReport(reportFilter?: ReportFilter, options?: any): AxiosPromise<ReportList> {
            return localVarFp.exportReport(reportFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportAPIApi - object-oriented interface
 * @export
 * @class ReportAPIApi
 * @extends {BaseAPI}
 */
export class ReportAPIApi extends BaseAPI {
    /**
     * Export Report. 
     * @param {ReportFilter} [reportFilter] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportAPIApi
     */
    public exportReport(reportFilter?: ReportFilter, options?: AxiosRequestConfig) {
        return ReportAPIApiFp(this.configuration).exportReport(reportFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScopesCategoriesAPIApi - axios parameter creator
 * @export
 */
export const ScopesCategoriesAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get categories 
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories: async (search?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get emission factor table nodes information 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmissionFactorTableNodes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/emissionFactorTableNodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get scopes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scopes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScopesCategoriesAPIApi - functional programming interface
 * @export
 */
export const ScopesCategoriesAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScopesCategoriesAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * get categories 
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategories(search?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategories(search, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get emission factor table nodes information 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmissionFactorTableNodes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmissionFactorTableNodeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmissionFactorTableNodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get scopes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScopes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scopes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScopes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScopesCategoriesAPIApi - factory interface
 * @export
 */
export const ScopesCategoriesAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScopesCategoriesAPIApiFp(configuration)
    return {
        /**
         * get categories 
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(search?: string, offset?: number, limit?: number, options?: any): AxiosPromise<CategoryList> {
            return localVarFp.getCategories(search, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get emission factor table nodes information 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmissionFactorTableNodes(options?: any): AxiosPromise<EmissionFactorTableNodeList> {
            return localVarFp.getEmissionFactorTableNodes(options).then((request) => request(axios, basePath));
        },
        /**
         * get scopes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopes(options?: any): AxiosPromise<Scopes> {
            return localVarFp.getScopes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScopesCategoriesAPIApi - object-oriented interface
 * @export
 * @class ScopesCategoriesAPIApi
 * @extends {BaseAPI}
 */
export class ScopesCategoriesAPIApi extends BaseAPI {
    /**
     * get categories 
     * @param {string} [search] Search
     * @param {number} [offset] offset on paging
     * @param {number} [limit] number record of page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopesCategoriesAPIApi
     */
    public getCategories(search?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return ScopesCategoriesAPIApiFp(this.configuration).getCategories(search, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get emission factor table nodes information 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopesCategoriesAPIApi
     */
    public getEmissionFactorTableNodes(options?: AxiosRequestConfig) {
        return ScopesCategoriesAPIApiFp(this.configuration).getEmissionFactorTableNodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get scopes 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopesCategoriesAPIApi
     */
    public getScopes(options?: AxiosRequestConfig) {
        return ScopesCategoriesAPIApiFp(this.configuration).getScopes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SiteAPIApi - axios parameter creator
 * @export
 */
export const SiteAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new site.  新規に拠点を作成します。 
         * @param {SiteCreate} [siteCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite: async (siteCreate?: SiteCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(siteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the specified site.  指定の拠点を削除します。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSite: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('deleteSite', 'siteId', siteId)
            const localVarPath = `/sites/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the specified site.  指定の拠点を取得します。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSite: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSite', 'siteId', siteId)
            const localVarPath = `/sites/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象拠点の直下の階層に属する拠点の一覧を取得します 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitesByParentSiteId: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSitesByParentSiteId', 'siteId', siteId)
            const localVarPath = `/sites/parentSite/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list site.  リスト サイトを取得します。 
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSite: async (search?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the specified site.  指定の拠点を更新します。 
         * @param {number} siteId Site ID
         * @param {SiteUpdate} [siteUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite: async (siteId: number, siteUpdate?: SiteUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('updateSite', 'siteId', siteId)
            const localVarPath = `/sites/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(siteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteAPIApi - functional programming interface
 * @export
 */
export const SiteAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new site.  新規に拠点を作成します。 
         * @param {SiteCreate} [siteCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSite(siteCreate?: SiteCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSite(siteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the specified site.  指定の拠点を削除します。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSite(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSite(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the specified site.  指定の拠点を取得します。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSite(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSite(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 対象拠点の直下の階層に属する拠点の一覧を取得します 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSitesByParentSiteId(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSitesByParentSiteId(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the list site.  リスト サイトを取得します。 
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSite(search?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSite(search, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the specified site.  指定の拠点を更新します。 
         * @param {number} siteId Site ID
         * @param {SiteUpdate} [siteUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSite(siteId: number, siteUpdate?: SiteUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSite(siteId, siteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteAPIApi - factory interface
 * @export
 */
export const SiteAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteAPIApiFp(configuration)
    return {
        /**
         * Creates a new site.  新規に拠点を作成します。 
         * @param {SiteCreate} [siteCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite(siteCreate?: SiteCreate, options?: any): AxiosPromise<Site> {
            return localVarFp.createSite(siteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the specified site.  指定の拠点を削除します。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSite(siteId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSite(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the specified site.  指定の拠点を取得します。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSite(siteId: number, options?: any): AxiosPromise<Site> {
            return localVarFp.getSite(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象拠点の直下の階層に属する拠点の一覧を取得します 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitesByParentSiteId(siteId: number, options?: any): AxiosPromise<SiteList> {
            return localVarFp.getSitesByParentSiteId(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list site.  リスト サイトを取得します。 
         * @param {string} [search] Search
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSite(search?: string, offset?: number, limit?: number, options?: any): AxiosPromise<SiteList> {
            return localVarFp.listSite(search, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the specified site.  指定の拠点を更新します。 
         * @param {number} siteId Site ID
         * @param {SiteUpdate} [siteUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite(siteId: number, siteUpdate?: SiteUpdate, options?: any): AxiosPromise<Site> {
            return localVarFp.updateSite(siteId, siteUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteAPIApi - object-oriented interface
 * @export
 * @class SiteAPIApi
 * @extends {BaseAPI}
 */
export class SiteAPIApi extends BaseAPI {
    /**
     * Creates a new site.  新規に拠点を作成します。 
     * @param {SiteCreate} [siteCreate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAPIApi
     */
    public createSite(siteCreate?: SiteCreate, options?: AxiosRequestConfig) {
        return SiteAPIApiFp(this.configuration).createSite(siteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the specified site.  指定の拠点を削除します。 
     * @param {number} siteId Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAPIApi
     */
    public deleteSite(siteId: number, options?: AxiosRequestConfig) {
        return SiteAPIApiFp(this.configuration).deleteSite(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the specified site.  指定の拠点を取得します。 
     * @param {number} siteId Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAPIApi
     */
    public getSite(siteId: number, options?: AxiosRequestConfig) {
        return SiteAPIApiFp(this.configuration).getSite(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象拠点の直下の階層に属する拠点の一覧を取得します 
     * @param {number} siteId Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAPIApi
     */
    public getSitesByParentSiteId(siteId: number, options?: AxiosRequestConfig) {
        return SiteAPIApiFp(this.configuration).getSitesByParentSiteId(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list site.  リスト サイトを取得します。 
     * @param {string} [search] Search
     * @param {number} [offset] offset on paging
     * @param {number} [limit] number record of page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAPIApi
     */
    public listSite(search?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return SiteAPIApiFp(this.configuration).listSite(search, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the specified site.  指定の拠点を更新します。 
     * @param {number} siteId Site ID
     * @param {SiteUpdate} [siteUpdate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAPIApi
     */
    public updateSite(siteId: number, siteUpdate?: SiteUpdate, options?: AxiosRequestConfig) {
        return SiteAPIApiFp(this.configuration).updateSite(siteId, siteUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SiteActivityAPIApi - axios parameter creator
 * @export
 */
export const SiteActivityAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 拠点の月・カテゴリにおける全てのActivityを取得します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {number} categoryId Category ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteActivities: async (siteId: number, month: string, categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteActivities', 'siteId', siteId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getSiteActivities', 'month', month)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getSiteActivities', 'categoryId', categoryId)
            const localVarPath = `/sites/{siteId}/activities`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 拠点の年度における全てのActivityと排出係数を取得します。 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteYearActivityWithEmissionFactor: async (siteId: number, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteYearActivityWithEmissionFactor', 'siteId', siteId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getSiteYearActivityWithEmissionFactor', 'year', year)
            const localVarPath = `/sites/{siteId}/year-activity-with-emission-factor`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 拠点の月・カテゴリにおける全てのActivityを置換します。 各Activityは以下の状況に応じて追加、更新、または削除されます。 - ActivityオブジェクトがIDを含まない場合: 新規に作成されます。 - ActivityオブジェクトがIDを含む場合: 既存のActivityと比較し、差分がある場合更新されます。 - それ以外の既存のActivity: 削除されます。  このAPIの実行後、指定した拠点の月のActivity入力状況は自動的に \"in progress\" に変更されます。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {number} categoryId Category ID
         * @param {Activities} [activities] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceSiteActivities: async (siteId: number, month: string, categoryId: number, activities?: Activities, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('replaceSiteActivities', 'siteId', siteId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('replaceSiteActivities', 'month', month)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('replaceSiteActivities', 'categoryId', categoryId)
            const localVarPath = `/sites/{siteId}/activities`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activities, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 拠点の月・カテゴリにおける全てのActivityを置換します。 各Activityは以下の状況に応じて追加、更新、または削除されます。 - ActivityオブジェクトがIDを含まない場合: 新規に作成されます。 - ActivityオブジェクトがIDを含む場合: 既存のActivityと比較し、差分がある場合更新されます。 - それ以外の既存のActivity: 削除されます。  このAPIの実行後、指定した拠点の月のActivity入力状況は自動的に \"in progress\" に変更されます。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {SaveActivities} [saveActivities] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteActivities: async (siteId: number, month: string, saveActivities?: SaveActivities, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('saveSiteActivities', 'siteId', siteId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('saveSiteActivities', 'month', month)
            const localVarPath = `/sites/{siteId}/activities`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveActivities, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 拠点の月・カテゴリにおける対象のActivityを新規作成・更新します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {SaveActivity} [saveActivity] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteActivity: async (siteId: number, month: string, saveActivity?: SaveActivity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('saveSiteActivity', 'siteId', siteId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('saveSiteActivity', 'month', month)
            const localVarPath = `/sites/{siteId}/activity`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveActivity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteActivityAPIApi - functional programming interface
 * @export
 */
export const SiteActivityAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteActivityAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 拠点の月・カテゴリにおける全てのActivityを取得します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {number} categoryId Category ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteActivities(siteId: number, month: string, categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activities>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteActivities(siteId, month, categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 拠点の年度における全てのActivityと排出係数を取得します。 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteYearActivityWithEmissionFactor(siteId: number, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityWithEmissionFactorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteYearActivityWithEmissionFactor(siteId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 拠点の月・カテゴリにおける全てのActivityを置換します。 各Activityは以下の状況に応じて追加、更新、または削除されます。 - ActivityオブジェクトがIDを含まない場合: 新規に作成されます。 - ActivityオブジェクトがIDを含む場合: 既存のActivityと比較し、差分がある場合更新されます。 - それ以外の既存のActivity: 削除されます。  このAPIの実行後、指定した拠点の月のActivity入力状況は自動的に \"in progress\" に変更されます。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {number} categoryId Category ID
         * @param {Activities} [activities] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceSiteActivities(siteId: number, month: string, categoryId: number, activities?: Activities, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceSiteActivities(siteId, month, categoryId, activities, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 拠点の月・カテゴリにおける全てのActivityを置換します。 各Activityは以下の状況に応じて追加、更新、または削除されます。 - ActivityオブジェクトがIDを含まない場合: 新規に作成されます。 - ActivityオブジェクトがIDを含む場合: 既存のActivityと比較し、差分がある場合更新されます。 - それ以外の既存のActivity: 削除されます。  このAPIの実行後、指定した拠点の月のActivity入力状況は自動的に \"in progress\" に変更されます。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {SaveActivities} [saveActivities] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSiteActivities(siteId: number, month: string, saveActivities?: SaveActivities, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSiteActivities(siteId, month, saveActivities, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 拠点の月・カテゴリにおける対象のActivityを新規作成・更新します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {SaveActivity} [saveActivity] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSiteActivity(siteId: number, month: string, saveActivity?: SaveActivity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSiteActivity(siteId, month, saveActivity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteActivityAPIApi - factory interface
 * @export
 */
export const SiteActivityAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteActivityAPIApiFp(configuration)
    return {
        /**
         * 拠点の月・カテゴリにおける全てのActivityを取得します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {number} categoryId Category ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteActivities(siteId: number, month: string, categoryId: number, options?: any): AxiosPromise<Activities> {
            return localVarFp.getSiteActivities(siteId, month, categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 拠点の年度における全てのActivityと排出係数を取得します。 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteYearActivityWithEmissionFactor(siteId: number, year: number, options?: any): AxiosPromise<ActivityWithEmissionFactorList> {
            return localVarFp.getSiteYearActivityWithEmissionFactor(siteId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 拠点の月・カテゴリにおける全てのActivityを置換します。 各Activityは以下の状況に応じて追加、更新、または削除されます。 - ActivityオブジェクトがIDを含まない場合: 新規に作成されます。 - ActivityオブジェクトがIDを含む場合: 既存のActivityと比較し、差分がある場合更新されます。 - それ以外の既存のActivity: 削除されます。  このAPIの実行後、指定した拠点の月のActivity入力状況は自動的に \"in progress\" に変更されます。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {number} categoryId Category ID
         * @param {Activities} [activities] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceSiteActivities(siteId: number, month: string, categoryId: number, activities?: Activities, options?: any): AxiosPromise<void> {
            return localVarFp.replaceSiteActivities(siteId, month, categoryId, activities, options).then((request) => request(axios, basePath));
        },
        /**
         * 拠点の月・カテゴリにおける全てのActivityを置換します。 各Activityは以下の状況に応じて追加、更新、または削除されます。 - ActivityオブジェクトがIDを含まない場合: 新規に作成されます。 - ActivityオブジェクトがIDを含む場合: 既存のActivityと比較し、差分がある場合更新されます。 - それ以外の既存のActivity: 削除されます。  このAPIの実行後、指定した拠点の月のActivity入力状況は自動的に \"in progress\" に変更されます。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {SaveActivities} [saveActivities] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteActivities(siteId: number, month: string, saveActivities?: SaveActivities, options?: any): AxiosPromise<void> {
            return localVarFp.saveSiteActivities(siteId, month, saveActivities, options).then((request) => request(axios, basePath));
        },
        /**
         * 拠点の月・カテゴリにおける対象のActivityを新規作成・更新します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {SaveActivity} [saveActivity] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteActivity(siteId: number, month: string, saveActivity?: SaveActivity, options?: any): AxiosPromise<void> {
            return localVarFp.saveSiteActivity(siteId, month, saveActivity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteActivityAPIApi - object-oriented interface
 * @export
 * @class SiteActivityAPIApi
 * @extends {BaseAPI}
 */
export class SiteActivityAPIApi extends BaseAPI {
    /**
     * 拠点の月・カテゴリにおける全てのActivityを取得します。 
     * @param {number} siteId Site ID
     * @param {string} month Month
     * @param {number} categoryId Category ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteActivityAPIApi
     */
    public getSiteActivities(siteId: number, month: string, categoryId: number, options?: AxiosRequestConfig) {
        return SiteActivityAPIApiFp(this.configuration).getSiteActivities(siteId, month, categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 拠点の年度における全てのActivityと排出係数を取得します。 
     * @param {number} siteId Site ID
     * @param {number} year Year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteActivityAPIApi
     */
    public getSiteYearActivityWithEmissionFactor(siteId: number, year: number, options?: AxiosRequestConfig) {
        return SiteActivityAPIApiFp(this.configuration).getSiteYearActivityWithEmissionFactor(siteId, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 拠点の月・カテゴリにおける全てのActivityを置換します。 各Activityは以下の状況に応じて追加、更新、または削除されます。 - ActivityオブジェクトがIDを含まない場合: 新規に作成されます。 - ActivityオブジェクトがIDを含む場合: 既存のActivityと比較し、差分がある場合更新されます。 - それ以外の既存のActivity: 削除されます。  このAPIの実行後、指定した拠点の月のActivity入力状況は自動的に \"in progress\" に変更されます。 
     * @param {number} siteId Site ID
     * @param {string} month Month
     * @param {number} categoryId Category ID
     * @param {Activities} [activities] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteActivityAPIApi
     */
    public replaceSiteActivities(siteId: number, month: string, categoryId: number, activities?: Activities, options?: AxiosRequestConfig) {
        return SiteActivityAPIApiFp(this.configuration).replaceSiteActivities(siteId, month, categoryId, activities, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 拠点の月・カテゴリにおける全てのActivityを置換します。 各Activityは以下の状況に応じて追加、更新、または削除されます。 - ActivityオブジェクトがIDを含まない場合: 新規に作成されます。 - ActivityオブジェクトがIDを含む場合: 既存のActivityと比較し、差分がある場合更新されます。 - それ以外の既存のActivity: 削除されます。  このAPIの実行後、指定した拠点の月のActivity入力状況は自動的に \"in progress\" に変更されます。 
     * @param {number} siteId Site ID
     * @param {string} month Month
     * @param {SaveActivities} [saveActivities] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteActivityAPIApi
     */
    public saveSiteActivities(siteId: number, month: string, saveActivities?: SaveActivities, options?: AxiosRequestConfig) {
        return SiteActivityAPIApiFp(this.configuration).saveSiteActivities(siteId, month, saveActivities, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 拠点の月・カテゴリにおける対象のActivityを新規作成・更新します。 
     * @param {number} siteId Site ID
     * @param {string} month Month
     * @param {SaveActivity} [saveActivity] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteActivityAPIApi
     */
    public saveSiteActivity(siteId: number, month: string, saveActivity?: SaveActivity, options?: AxiosRequestConfig) {
        return SiteActivityAPIApiFp(this.configuration).saveSiteActivity(siteId, month, saveActivity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SiteCategoryTransactionAPIApi - axios parameter creator
 * @export
 */
export const SiteCategoryTransactionAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 対象の拠点、および月のCategoryTransactionの一覧を取得します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {GetSiteCategoryTransactionsRequest} [getSiteCategoryTransactionsRequest] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteCategoryTransactions: async (siteId: number, month: string, getSiteCategoryTransactionsRequest?: GetSiteCategoryTransactionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteCategoryTransactions', 'siteId', siteId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getSiteCategoryTransactions', 'month', month)
            const localVarPath = `/sites/{siteId}/category-transactions`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSiteCategoryTransactionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象の拠点、および月のCategoryTransactionを新規作成・更新します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {SaveSiteCategoryTransactionRequest} [saveSiteCategoryTransactionRequest] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteCategoryTransaction: async (siteId: number, month: string, saveSiteCategoryTransactionRequest?: SaveSiteCategoryTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('saveSiteCategoryTransaction', 'siteId', siteId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('saveSiteCategoryTransaction', 'month', month)
            const localVarPath = `/sites/{siteId}/category-transaction`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveSiteCategoryTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteCategoryTransactionAPIApi - functional programming interface
 * @export
 */
export const SiteCategoryTransactionAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteCategoryTransactionAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 対象の拠点、および月のCategoryTransactionの一覧を取得します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {GetSiteCategoryTransactionsRequest} [getSiteCategoryTransactionsRequest] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteCategoryTransactions(siteId: number, month: string, getSiteCategoryTransactionsRequest?: GetSiteCategoryTransactionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteCategoryTransactionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteCategoryTransactions(siteId, month, getSiteCategoryTransactionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 対象の拠点、および月のCategoryTransactionを新規作成・更新します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {SaveSiteCategoryTransactionRequest} [saveSiteCategoryTransactionRequest] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSiteCategoryTransaction(siteId: number, month: string, saveSiteCategoryTransactionRequest?: SaveSiteCategoryTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSiteCategoryTransaction(siteId, month, saveSiteCategoryTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteCategoryTransactionAPIApi - factory interface
 * @export
 */
export const SiteCategoryTransactionAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteCategoryTransactionAPIApiFp(configuration)
    return {
        /**
         * 対象の拠点、および月のCategoryTransactionの一覧を取得します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {GetSiteCategoryTransactionsRequest} [getSiteCategoryTransactionsRequest] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteCategoryTransactions(siteId: number, month: string, getSiteCategoryTransactionsRequest?: GetSiteCategoryTransactionsRequest, options?: any): AxiosPromise<SiteCategoryTransactionList> {
            return localVarFp.getSiteCategoryTransactions(siteId, month, getSiteCategoryTransactionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象の拠点、および月のCategoryTransactionを新規作成・更新します。 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {SaveSiteCategoryTransactionRequest} [saveSiteCategoryTransactionRequest] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteCategoryTransaction(siteId: number, month: string, saveSiteCategoryTransactionRequest?: SaveSiteCategoryTransactionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.saveSiteCategoryTransaction(siteId, month, saveSiteCategoryTransactionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteCategoryTransactionAPIApi - object-oriented interface
 * @export
 * @class SiteCategoryTransactionAPIApi
 * @extends {BaseAPI}
 */
export class SiteCategoryTransactionAPIApi extends BaseAPI {
    /**
     * 対象の拠点、および月のCategoryTransactionの一覧を取得します。 
     * @param {number} siteId Site ID
     * @param {string} month Month
     * @param {GetSiteCategoryTransactionsRequest} [getSiteCategoryTransactionsRequest] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteCategoryTransactionAPIApi
     */
    public getSiteCategoryTransactions(siteId: number, month: string, getSiteCategoryTransactionsRequest?: GetSiteCategoryTransactionsRequest, options?: AxiosRequestConfig) {
        return SiteCategoryTransactionAPIApiFp(this.configuration).getSiteCategoryTransactions(siteId, month, getSiteCategoryTransactionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象の拠点、および月のCategoryTransactionを新規作成・更新します。 
     * @param {number} siteId Site ID
     * @param {string} month Month
     * @param {SaveSiteCategoryTransactionRequest} [saveSiteCategoryTransactionRequest] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteCategoryTransactionAPIApi
     */
    public saveSiteCategoryTransaction(siteId: number, month: string, saveSiteCategoryTransactionRequest?: SaveSiteCategoryTransactionRequest, options?: AxiosRequestConfig) {
        return SiteCategoryTransactionAPIApiFp(this.configuration).saveSiteCategoryTransaction(siteId, month, saveSiteCategoryTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SiteNodeAPIApi - axios parameter creator
 * @export
 */
export const SiteNodeAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 対象企業に紐づく拠点ノード一覧を取得します 
         * @param {number} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteNodes: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getSiteNodes', 'organizationId', organizationId)
            const localVarPath = `/site-nodes/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象企業に紐づく拠点ノードの変更差分を保存します 
         * @param {number} organizationId Organization ID
         * @param {SiteNodesSave} [siteNodesSave] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteNodes: async (organizationId: number, siteNodesSave?: SiteNodesSave, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('saveSiteNodes', 'organizationId', organizationId)
            const localVarPath = `/site-nodes/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(siteNodesSave, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteNodeAPIApi - functional programming interface
 * @export
 */
export const SiteNodeAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteNodeAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 対象企業に紐づく拠点ノード一覧を取得します 
         * @param {number} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteNodes(organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteNodes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteNodes(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 対象企業に紐づく拠点ノードの変更差分を保存します 
         * @param {number} organizationId Organization ID
         * @param {SiteNodesSave} [siteNodesSave] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSiteNodes(organizationId: number, siteNodesSave?: SiteNodesSave, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSiteNodes(organizationId, siteNodesSave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteNodeAPIApi - factory interface
 * @export
 */
export const SiteNodeAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteNodeAPIApiFp(configuration)
    return {
        /**
         * 対象企業に紐づく拠点ノード一覧を取得します 
         * @param {number} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteNodes(organizationId: number, options?: any): AxiosPromise<SiteNodes> {
            return localVarFp.getSiteNodes(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象企業に紐づく拠点ノードの変更差分を保存します 
         * @param {number} organizationId Organization ID
         * @param {SiteNodesSave} [siteNodesSave] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteNodes(organizationId: number, siteNodesSave?: SiteNodesSave, options?: any): AxiosPromise<void> {
            return localVarFp.saveSiteNodes(organizationId, siteNodesSave, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteNodeAPIApi - object-oriented interface
 * @export
 * @class SiteNodeAPIApi
 * @extends {BaseAPI}
 */
export class SiteNodeAPIApi extends BaseAPI {
    /**
     * 対象企業に紐づく拠点ノード一覧を取得します 
     * @param {number} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteNodeAPIApi
     */
    public getSiteNodes(organizationId: number, options?: AxiosRequestConfig) {
        return SiteNodeAPIApiFp(this.configuration).getSiteNodes(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象企業に紐づく拠点ノードの変更差分を保存します 
     * @param {number} organizationId Organization ID
     * @param {SiteNodesSave} [siteNodesSave] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteNodeAPIApi
     */
    public saveSiteNodes(organizationId: number, siteNodesSave?: SiteNodesSave, options?: AxiosRequestConfig) {
        return SiteNodeAPIApiFp(this.configuration).saveSiteNodes(organizationId, siteNodesSave, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SiteOrganizationBatchUpdateAPIApi - axios parameter creator
 * @export
 */
export const SiteOrganizationBatchUpdateAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates or updates batch objective of the specified site of the year.  指定されたサイト オブ ザ イヤーの多目的を作成または更新します 
         * @param {Array<SiteUpdateBatch>} [siteUpdateBatch] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBatchSiteObjective: async (siteUpdateBatch?: Array<SiteUpdateBatch>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sites/objectives/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(siteUpdateBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteOrganizationBatchUpdateAPIApi - functional programming interface
 * @export
 */
export const SiteOrganizationBatchUpdateAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteOrganizationBatchUpdateAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates or updates batch objective of the specified site of the year.  指定されたサイト オブ ザ イヤーの多目的を作成または更新します 
         * @param {Array<SiteUpdateBatch>} [siteUpdateBatch] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveBatchSiteObjective(siteUpdateBatch?: Array<SiteUpdateBatch>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SiteUpdateBatch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveBatchSiteObjective(siteUpdateBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteOrganizationBatchUpdateAPIApi - factory interface
 * @export
 */
export const SiteOrganizationBatchUpdateAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteOrganizationBatchUpdateAPIApiFp(configuration)
    return {
        /**
         * Creates or updates batch objective of the specified site of the year.  指定されたサイト オブ ザ イヤーの多目的を作成または更新します 
         * @param {Array<SiteUpdateBatch>} [siteUpdateBatch] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBatchSiteObjective(siteUpdateBatch?: Array<SiteUpdateBatch>, options?: any): AxiosPromise<Array<SiteUpdateBatch>> {
            return localVarFp.saveBatchSiteObjective(siteUpdateBatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteOrganizationBatchUpdateAPIApi - object-oriented interface
 * @export
 * @class SiteOrganizationBatchUpdateAPIApi
 * @extends {BaseAPI}
 */
export class SiteOrganizationBatchUpdateAPIApi extends BaseAPI {
    /**
     * Creates or updates batch objective of the specified site of the year.  指定されたサイト オブ ザ イヤーの多目的を作成または更新します 
     * @param {Array<SiteUpdateBatch>} [siteUpdateBatch] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteOrganizationBatchUpdateAPIApi
     */
    public saveBatchSiteObjective(siteUpdateBatch?: Array<SiteUpdateBatch>, options?: AxiosRequestConfig) {
        return SiteOrganizationBatchUpdateAPIApiFp(this.configuration).saveBatchSiteObjective(siteUpdateBatch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SiteSubmissionAPIApi - axios parameter creator
 * @export
 */
export const SiteSubmissionAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the activity input status for each month of the site.  Available statuses are \"in progress\" and \"done\". Months without any activity are not included in the response.   拠点の月ごとのActivity入力状況を取得します。 有効な状態は \"in progress\" と \"done\" です。 Acitivityの存在しない月はレスポンスには含まれません。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteSubmissions: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteSubmissions', 'siteId', siteId)
            const localVarPath = `/sites/{siteId}/submissions`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves the activity input status for the month of the site. Emissions aggregation will be performed after setting status to \"done\". You can fetch annual and monthly emission summaries by getSiteSummary or getOrganizationSummary API.  拠点の月のActivity入力状況を更新します。 状態を \"done\" に変更すると、排出量の集計が実行されます。 集計された結果は getSiteSummary または getOrganizationSummary API で取得可能です。 
         * @param {number} siteId Site ID
         * @param {SubmissionRequest} [submissionRequest] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteSubmissions: async (siteId: number, submissionRequest?: SubmissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('saveSiteSubmissions', 'siteId', siteId)
            const localVarPath = `/sites/{siteId}/submissions`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteSubmissionAPIApi - functional programming interface
 * @export
 */
export const SiteSubmissionAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteSubmissionAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the activity input status for each month of the site.  Available statuses are \"in progress\" and \"done\". Months without any activity are not included in the response.   拠点の月ごとのActivity入力状況を取得します。 有効な状態は \"in progress\" と \"done\" です。 Acitivityの存在しない月はレスポンスには含まれません。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteSubmissions(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteSubmissions(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Saves the activity input status for the month of the site. Emissions aggregation will be performed after setting status to \"done\". You can fetch annual and monthly emission summaries by getSiteSummary or getOrganizationSummary API.  拠点の月のActivity入力状況を更新します。 状態を \"done\" に変更すると、排出量の集計が実行されます。 集計された結果は getSiteSummary または getOrganizationSummary API で取得可能です。 
         * @param {number} siteId Site ID
         * @param {SubmissionRequest} [submissionRequest] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSiteSubmissions(siteId: number, submissionRequest?: SubmissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSiteSubmissions(siteId, submissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteSubmissionAPIApi - factory interface
 * @export
 */
export const SiteSubmissionAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteSubmissionAPIApiFp(configuration)
    return {
        /**
         * Gets the activity input status for each month of the site.  Available statuses are \"in progress\" and \"done\". Months without any activity are not included in the response.   拠点の月ごとのActivity入力状況を取得します。 有効な状態は \"in progress\" と \"done\" です。 Acitivityの存在しない月はレスポンスには含まれません。 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteSubmissions(siteId: number, options?: any): AxiosPromise<Submissions> {
            return localVarFp.getSiteSubmissions(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Saves the activity input status for the month of the site. Emissions aggregation will be performed after setting status to \"done\". You can fetch annual and monthly emission summaries by getSiteSummary or getOrganizationSummary API.  拠点の月のActivity入力状況を更新します。 状態を \"done\" に変更すると、排出量の集計が実行されます。 集計された結果は getSiteSummary または getOrganizationSummary API で取得可能です。 
         * @param {number} siteId Site ID
         * @param {SubmissionRequest} [submissionRequest] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSiteSubmissions(siteId: number, submissionRequest?: SubmissionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.saveSiteSubmissions(siteId, submissionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteSubmissionAPIApi - object-oriented interface
 * @export
 * @class SiteSubmissionAPIApi
 * @extends {BaseAPI}
 */
export class SiteSubmissionAPIApi extends BaseAPI {
    /**
     * Gets the activity input status for each month of the site.  Available statuses are \"in progress\" and \"done\". Months without any activity are not included in the response.   拠点の月ごとのActivity入力状況を取得します。 有効な状態は \"in progress\" と \"done\" です。 Acitivityの存在しない月はレスポンスには含まれません。 
     * @param {number} siteId Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteSubmissionAPIApi
     */
    public getSiteSubmissions(siteId: number, options?: AxiosRequestConfig) {
        return SiteSubmissionAPIApiFp(this.configuration).getSiteSubmissions(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Saves the activity input status for the month of the site. Emissions aggregation will be performed after setting status to \"done\". You can fetch annual and monthly emission summaries by getSiteSummary or getOrganizationSummary API.  拠点の月のActivity入力状況を更新します。 状態を \"done\" に変更すると、排出量の集計が実行されます。 集計された結果は getSiteSummary または getOrganizationSummary API で取得可能です。 
     * @param {number} siteId Site ID
     * @param {SubmissionRequest} [submissionRequest] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteSubmissionAPIApi
     */
    public saveSiteSubmissions(siteId: number, submissionRequest?: SubmissionRequest, options?: AxiosRequestConfig) {
        return SiteSubmissionAPIApiFp(this.configuration).saveSiteSubmissions(siteId, submissionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SiteSummaryAPIApi - axios parameter creator
 * @export
 */
export const SiteSummaryAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnualSiteSummary: async (siteId: number, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getAnnualSiteSummary', 'siteId', siteId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getAnnualSiteSummary', 'year', year)
            const localVarPath = `/sites/{siteId}/summary/years/{year}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {number} half Half
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHalfYearSiteSummary: async (siteId: number, year: number, half: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getHalfYearSiteSummary', 'siteId', siteId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getHalfYearSiteSummary', 'year', year)
            // verify required parameter 'half' is not null or undefined
            assertParamExists('getHalfYearSiteSummary', 'half', half)
            const localVarPath = `/sites/{siteId}/summary/years/{year}/half/{half}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"half"}}`, encodeURIComponent(String(half)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets CO2 emission summary of the site. This includes: - Total emissions per month - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthSiteSummary: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getMonthSiteSummary', 'siteId', siteId)
            const localVarPath = `/sites/{siteId}/summary/months`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets monthly CO2 emission summary of the site. This includes: - Total emissions per scope & category - Top 10 critical emission factors in each category  拠点の月間のCO2排出量集計結果を取得します。これは以下を含みます: - 各スコープ・カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlySiteSummary: async (siteId: number, month: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getMonthlySiteSummary', 'siteId', siteId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getMonthlySiteSummary', 'month', month)
            const localVarPath = `/sites/{siteId}/summary/months/{month}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {number} quarter Quarter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuarterlySiteSummary: async (siteId: number, year: number, quarter: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getQuarterlySiteSummary', 'siteId', siteId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getQuarterlySiteSummary', 'year', year)
            // verify required parameter 'quarter' is not null or undefined
            assertParamExists('getQuarterlySiteSummary', 'quarter', quarter)
            const localVarPath = `/sites/{siteId}/summary/years/{year}/quarter/{quarter}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"quarter"}}`, encodeURIComponent(String(quarter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets CO2 emission summary of the site. This includes: - Total emissions per year - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYearSiteSummary: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getYearSiteSummary', 'siteId', siteId)
            const localVarPath = `/sites/{siteId}/summary/years`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteSummaryAPIApi - functional programming interface
 * @export
 */
export const SiteSummaryAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteSummaryAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnualSiteSummary(siteId: number, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnualSiteSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnualSiteSummary(siteId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {number} half Half
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHalfYearSiteSummary(siteId: number, year: number, half: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HalfYearSiteSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHalfYearSiteSummary(siteId, year, half, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets CO2 emission summary of the site. This includes: - Total emissions per month - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthSiteSummary(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthSiteSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthSiteSummary(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets monthly CO2 emission summary of the site. This includes: - Total emissions per scope & category - Top 10 critical emission factors in each category  拠点の月間のCO2排出量集計結果を取得します。これは以下を含みます: - 各スコープ・カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlySiteSummary(siteId: number, month: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlySiteSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlySiteSummary(siteId, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {number} quarter Quarter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuarterlySiteSummary(siteId: number, year: number, quarter: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuarterlySiteSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuarterlySiteSummary(siteId, year, quarter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets CO2 emission summary of the site. This includes: - Total emissions per year - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYearSiteSummary(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YearSiteSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYearSiteSummary(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteSummaryAPIApi - factory interface
 * @export
 */
export const SiteSummaryAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteSummaryAPIApiFp(configuration)
    return {
        /**
         * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnualSiteSummary(siteId: number, year: number, options?: any): AxiosPromise<AnnualSiteSummary> {
            return localVarFp.getAnnualSiteSummary(siteId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {number} half Half
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHalfYearSiteSummary(siteId: number, year: number, half: number, options?: any): AxiosPromise<HalfYearSiteSummary> {
            return localVarFp.getHalfYearSiteSummary(siteId, year, half, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets CO2 emission summary of the site. This includes: - Total emissions per month - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthSiteSummary(siteId: number, options?: any): AxiosPromise<MonthSiteSummary> {
            return localVarFp.getMonthSiteSummary(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets monthly CO2 emission summary of the site. This includes: - Total emissions per scope & category - Top 10 critical emission factors in each category  拠点の月間のCO2排出量集計結果を取得します。これは以下を含みます: - 各スコープ・カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {string} month Month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlySiteSummary(siteId: number, month: string, options?: any): AxiosPromise<MonthlySiteSummary> {
            return localVarFp.getMonthlySiteSummary(siteId, month, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
         * @param {number} siteId Site ID
         * @param {number} year Year
         * @param {number} quarter Quarter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuarterlySiteSummary(siteId: number, year: number, quarter: number, options?: any): AxiosPromise<QuarterlySiteSummary> {
            return localVarFp.getQuarterlySiteSummary(siteId, year, quarter, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets CO2 emission summary of the site. This includes: - Total emissions per year - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
         * @param {number} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYearSiteSummary(siteId: number, options?: any): AxiosPromise<YearSiteSummary> {
            return localVarFp.getYearSiteSummary(siteId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteSummaryAPIApi - object-oriented interface
 * @export
 * @class SiteSummaryAPIApi
 * @extends {BaseAPI}
 */
export class SiteSummaryAPIApi extends BaseAPI {
    /**
     * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
     * @param {number} siteId Site ID
     * @param {number} year Year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteSummaryAPIApi
     */
    public getAnnualSiteSummary(siteId: number, year: number, options?: AxiosRequestConfig) {
        return SiteSummaryAPIApiFp(this.configuration).getAnnualSiteSummary(siteId, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
     * @param {number} siteId Site ID
     * @param {number} year Year
     * @param {number} half Half
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteSummaryAPIApi
     */
    public getHalfYearSiteSummary(siteId: number, year: number, half: number, options?: AxiosRequestConfig) {
        return SiteSummaryAPIApiFp(this.configuration).getHalfYearSiteSummary(siteId, year, half, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets CO2 emission summary of the site. This includes: - Total emissions per month - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
     * @param {number} siteId Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteSummaryAPIApi
     */
    public getMonthSiteSummary(siteId: number, options?: AxiosRequestConfig) {
        return SiteSummaryAPIApiFp(this.configuration).getMonthSiteSummary(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets monthly CO2 emission summary of the site. This includes: - Total emissions per scope & category - Top 10 critical emission factors in each category  拠点の月間のCO2排出量集計結果を取得します。これは以下を含みます: - 各スコープ・カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
     * @param {number} siteId Site ID
     * @param {string} month Month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteSummaryAPIApi
     */
    public getMonthlySiteSummary(siteId: number, month: string, options?: AxiosRequestConfig) {
        return SiteSummaryAPIApiFp(this.configuration).getMonthlySiteSummary(siteId, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets annual CO2 emission summary of the site. This includes: - Total emissions per category - Top 10 critical emission factors in each category  拠点の月のCO2排出量集計結果を取得します。これは以下を含みます: - 各カテゴリごとのCO2総排出量 - 各カテゴリ内で最もCO2排出量の多い排出原単位トップ10 
     * @param {number} siteId Site ID
     * @param {number} year Year
     * @param {number} quarter Quarter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteSummaryAPIApi
     */
    public getQuarterlySiteSummary(siteId: number, year: number, quarter: number, options?: AxiosRequestConfig) {
        return SiteSummaryAPIApiFp(this.configuration).getQuarterlySiteSummary(siteId, year, quarter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets CO2 emission summary of the site. This includes: - Total emissions per year - Total emissions per scope & category - Total emissions per emissionFactorTable  組織の年間のCO2排出量集計結果を取得します。これは以下を含みます: - 年間総排出量 - カテゴリーごとの総排出量 - 排出係数テーブルごとの総排出量 
     * @param {number} siteId Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteSummaryAPIApi
     */
    public getYearSiteSummary(siteId: number, options?: AxiosRequestConfig) {
        return SiteSummaryAPIApiFp(this.configuration).getYearSiteSummary(siteId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserAPIApi - axios parameter creator
 * @export
 */
export const UserAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Change user email.  ユーザーの電子メールを変更します。。 
         * @param {string} userId User ID
         * @param {UserChangeEmail} [userChangeEmail] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserEmail: async (userId: string, userChangeEmail?: UserChangeEmail, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('changeUserEmail', 'userId', userId)
            const localVarPath = `/users/{userId}/changeEmail`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userChangeEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new user.  新規にユーザーを作成します。 
         * @param {UserCreate} [userCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreate?: UserCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the specified user.  指定のユーザーを削除します。 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the profile information.  プロファイル情報を取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the specified user.  指定のユーザーを取得します。 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list user.  リスト ユーザーを取得します。 
         * @param {string} [search] Search
         * @param {number} [role] Role
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUser: async (search?: string, role?: number, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the specified user.  指定のユーザーを更新します。 
         * @param {string} userId User ID
         * @param {UserUpdate} [userUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, userUpdate?: UserUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAPIApi - functional programming interface
 * @export
 */
export const UserAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Change user email.  ユーザーの電子メールを変更します。。 
         * @param {string} userId User ID
         * @param {UserChangeEmail} [userChangeEmail] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserEmail(userId: string, userChangeEmail?: UserChangeEmail, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserEmail(userId, userChangeEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new user.  新規にユーザーを作成します。 
         * @param {UserCreate} [userCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreate?: UserCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the specified user.  指定のユーザーを削除します。 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the profile information.  プロファイル情報を取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the specified user.  指定のユーザーを取得します。 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the list user.  リスト ユーザーを取得します。 
         * @param {string} [search] Search
         * @param {number} [role] Role
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUser(search?: string, role?: number, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUser(search, role, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the specified user.  指定のユーザーを更新します。 
         * @param {string} userId User ID
         * @param {UserUpdate} [userUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, userUpdate?: UserUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, userUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAPIApi - factory interface
 * @export
 */
export const UserAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAPIApiFp(configuration)
    return {
        /**
         * Change user email.  ユーザーの電子メールを変更します。。 
         * @param {string} userId User ID
         * @param {UserChangeEmail} [userChangeEmail] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserEmail(userId: string, userChangeEmail?: UserChangeEmail, options?: any): AxiosPromise<User> {
            return localVarFp.changeUserEmail(userId, userChangeEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new user.  新規にユーザーを作成します。 
         * @param {UserCreate} [userCreate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreate?: UserCreate, options?: any): AxiosPromise<User> {
            return localVarFp.createUser(userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the specified user.  指定のユーザーを削除します。 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the profile information.  プロファイル情報を取得します。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<User> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the specified user.  指定のユーザーを取得します。 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list user.  リスト ユーザーを取得します。 
         * @param {string} [search] Search
         * @param {number} [role] Role
         * @param {number} [offset] offset on paging
         * @param {number} [limit] number record of page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUser(search?: string, role?: number, offset?: number, limit?: number, options?: any): AxiosPromise<UserList> {
            return localVarFp.listUser(search, role, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the specified user.  指定のユーザーを更新します。 
         * @param {string} userId User ID
         * @param {UserUpdate} [userUpdate] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, userUpdate?: UserUpdate, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(userId, userUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAPIApi - object-oriented interface
 * @export
 * @class UserAPIApi
 * @extends {BaseAPI}
 */
export class UserAPIApi extends BaseAPI {
    /**
     * Change user email.  ユーザーの電子メールを変更します。。 
     * @param {string} userId User ID
     * @param {UserChangeEmail} [userChangeEmail] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public changeUserEmail(userId: string, userChangeEmail?: UserChangeEmail, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).changeUserEmail(userId, userChangeEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new user.  新規にユーザーを作成します。 
     * @param {UserCreate} [userCreate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public createUser(userCreate?: UserCreate, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).createUser(userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the specified user.  指定のユーザーを削除します。 
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public deleteUser(userId: string, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the profile information.  プロファイル情報を取得します。 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public getProfile(options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the specified user.  指定のユーザーを取得します。 
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public getUser(userId: string, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list user.  リスト ユーザーを取得します。 
     * @param {string} [search] Search
     * @param {number} [role] Role
     * @param {number} [offset] offset on paging
     * @param {number} [limit] number record of page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public listUser(search?: string, role?: number, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).listUser(search, role, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the specified user.  指定のユーザーを更新します。 
     * @param {string} userId User ID
     * @param {UserUpdate} [userUpdate] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAPIApi
     */
    public updateUser(userId: string, userUpdate?: UserUpdate, options?: AxiosRequestConfig) {
        return UserAPIApiFp(this.configuration).updateUser(userId, userUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


